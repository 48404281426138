import { baseHTTP, HTTP } from '@/core';
import { AuthResponse } from './interface';

export default class AuthService {
  static async auth(login: string, password: string): Promise<AuthResponse> {
    const { data } = await baseHTTP.post('auth', { login, password });

    return data;
  }

  static async authAs(userId: string): Promise<AuthResponse> {
    const { data } = await HTTP.post('auth/as', { userId });

    return data;
  }

  static async sendOTP(email: string): Promise<void> {
    await baseHTTP.post('auth/otp', { email });
  }

  static async isOTPValid(otp: string): Promise<boolean> {
    const { data } = await baseHTTP.post('auth/check-otp', { otp });

    return data.isValid;
  }

  static async changePassword(otp: string, password: string): Promise<void> {
    await baseHTTP.post('auth/change-password', { otp, password });
  }

  static async isBlocked(): Promise<boolean> {
    const { data } = await HTTP.get('auth/is-blocked');

    return data.isBlocked;
  }
}
