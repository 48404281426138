import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UserInfo } from '@/store';
import { MenuItem, getMenuItems } from '@/core';
import conf from '../../../package.json';

@Component
export default class AppDrawer extends Vue {
  @Prop({ required: true, default: true })
  public isOpen!: boolean;

  @Prop({ required: true })
  public user!: UserInfo;

  public version = conf.version;
  public menuItems: MenuItem[] = [];

  public drawerOpen = true;

  public mounted(): void {
    this.menuItems = getMenuItems(this.user);
  }

  @Watch('user')
  private authenticatedUserChanged(): void {
    this.menuItems = getMenuItems(this.user);
  }
}
