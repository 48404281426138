import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Notification } from '@/store';
import { FormControlClass } from '@/core';

const sharedStore = namespace('Shared');

@Component
export default class DropZone extends FormControlClass<string> {
  @Ref() public readonly fileInputEl!: HTMLFormElement;

  @sharedStore.Mutation
  protected readonly setNotification!: (notification: Notification) => void;

  @Prop()
  private readonly availableTypes: string[] | undefined;

  @Prop({ required: false, default: false })
  private readonly customSize: boolean | undefined;

  @Prop({ required: false, default: true })
  private readonly hoverText: boolean | undefined;

  @Prop({ default: false })
  private readonly showSpinner!: boolean
  /**
   * Max file size in Mb
   */
  @Prop()
  private readonly maxFileSize: number | undefined;
  public isHover = false;

  public isDropZoneActive = false;
  public inActiveTimeout: number | undefined;
  public imageURL: string | null = null;
  public isFileType = false;
  public isTouched = false;

  public hover() {
    this.isHover = true;
  }

  public hoverExit() {
    this.isHover = false;
  }

  public toggleDropZoneActive(state: boolean): void {
    if (this.showSpinner) return;

    if (state) {
      this.isDropZoneActive = true;
      clearTimeout(this.inActiveTimeout);
    } else {
      this.inActiveTimeout = setTimeout(() => {
        this.isDropZoneActive = false;
      }, 50);
    }
  }

  public fileDropped($event: DragEvent): void {
    if (this.showSpinner) return;

    this.isDropZoneActive = false;
    this.isTouched = true;
    if ($event.dataTransfer) {
      try {
        this.fileTypeValidation($event.dataTransfer.files[0]);

        this.filesChanged($event.dataTransfer.files[0]);
      } catch (e) {
        console.error(e);
      }
    }
  }

  public async filesSelected($event: Event): Promise<void> {
    if (this.showSpinner) return;

    this.isTouched = true;
    const event = $event.target as HTMLInputElement;

    if (!event.files || !event.files.length) return;

    try {
      this.fileTypeValidation(event.files[0]);

      this.filesChanged(event.files[0]);
    } catch (e) {
      console.error(e);
    }
  }

  private fileTypeValidation(file: File): boolean {
    if (!this.availableTypes || !this.maxFileSize) return true;

    if (file.size / 1024 / 1024 > this.maxFileSize) {
      this.setNotification({
        text: `Max file size. Max ${this.maxFileSize} Mb`,
        type: 'error',
      });

      throw Error('Max file size');
    }

    if (!this.availableTypes?.includes(file.type)) {
      this.setNotification({
        text: `File has invalid format. Only ${this.availableTypes?.join(',')} supported`,
        type: 'error',
      });

      throw Error('File not supported');
    }

    return true;
  }

  private async getFileBase64(file: File): Promise<string> {
    return new Promise(r => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (!reader.result) return;

        const base64String = (reader.result as string)
          .replace('data:', '')
          .replace(/^.+,/, '');

        r(base64String);
      };
      reader.readAsDataURL(file);
    });
  }

  public clear(): void {
    if (this.showSpinner) return;

    this.imageURL = null;
    this.isFileType = false;
    this.$emit('input', null);
    this.$emit('fileRemoved');
  }

  public documentPreview(): void {
    window.open(this.value, '_blank');
  }

  @Emit('input')
  private async filesChanged(file: File): Promise<string> {
    if (['image/png', 'image/jpeg', 'image/svg+xml'].includes(file.type)) {
      this.imageURL = URL.createObjectURL(file);
      this.isFileType = false;
    } else {
      this.isFileType = true;
      this.imageURL = null;
    }

    return this.getFileBase64(file);
  }

  @Watch('value', { immediate: true })
  private fileChanged(): void {
    if (this.isTouched) return;

    if (!this.value) {
      this.imageURL = null;
      this.isFileType = false;
      return;
    }

    if (this.value.includes('pdf')) {
      this.isFileType = true;
    }

    if (
      this.value.includes('jpg') ||
      this.value.includes('png') ||
      this.value.includes('jpeg') ||
      this.value.includes('svg')
    ) {
      this.imageURL = this.value;
    }
  }

  get isClearableShow(): boolean {
    return this.clearable && (!!this.imageURL || this.isFileType);
  }
}
