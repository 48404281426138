import { Component, Prop } from 'vue-property-decorator';
import { UserService } from '@/services';
import { FormControlClass } from '@/core';

@Component
export default class DriverSelect extends FormControlClass<string> {
  @Prop({ default: false })
  private readonly showVehicleAssign!: boolean;

  public async setData(): Promise<void> {
    try {
      this.isLoading = true;

      const users = await UserService.findAllDrivers();

      this.items = users.filter(u => u.contact && !u.isBlocked).map(u => {
        const fullName = `${u.contact!.firstName} ${u.contact?.middleName || ''} ${u.contact!.lastName}`;
        let vehicleAssigned = '';

        if (this.showVehicleAssign) {
          vehicleAssigned = `${u.isVehicleAssigned ? 'Assigned' : 'Not Assigned'}`;
        }

        return {
          value: u.driverId!,
          text: `${fullName} ${vehicleAssigned}`,
        };
      });
    } finally {
      this.isLoading = false;
    }
  }
}
