import store from '@/store';

export function showErrorNotification(error: string | string[]): void {
  if (error === 'At least two points required') {
    store.commit('Shared/setNotification', {
      type: 'info',
      text: Array.isArray(error) ? error.join('<br>') : error,
    });
  } else {
    store.commit('Shared/setNotification', {
      type: 'error',
      text: Array.isArray(error) ? error.join('<br>') : error,
    });
  }
}
