import { Component, Emit, Mixins } from 'vue-property-decorator';
import FormClass from './Form.class';
import { DialogClass } from './Dialog.class';

@Component
/**
 * Contain all necessary methods and events for work form and dialog state.
 */
export class DialogFormClass extends Mixins(FormClass, DialogClass) {
  protected dialogOpened(formData?: any): void {
    if (formData && formData.id) {
      this.id = formData.id;

      if (this.formData && typeof this.formData === 'object') {
        Object.keys(this.formData).forEach(key => {
          if (key in formData) {
            // @ts-ignore
            this.formData[key as keyof typeof this.formData] = formData[key as keyof typeof formData];
          }
        });
      }
    }
  }

  protected dialogClosed(): void {
    this.resetForm();
  }

  protected resetForm(): void {
    // Small dilay for make actions with form after dialog closing.
    // Avoid clearing form during closing dialog animation for better UI view.
    setTimeout(() => {
      this.id = null;

      if (this.formEl) {
        this.formEl.resetValidation();
      }

      if (this.formData && this.originalFormData) {
        this.formData = JSON.parse(JSON.stringify(this.originalFormData));
      }
    }, 300);
  }
  /**
   * Emit common event when form was changed (save or update event).
   */
  @Emit('modelChanged')
  protected modelChanged<T>(event?: T) {
    return event;
  }
}
