import { Component, Prop } from 'vue-property-decorator';
import { DialogFormClass } from '@/core';
import { DialogWindow } from '@/components';
import { DriverInfo } from '@/pages/TripsPage/interfaces/driverInfo.interface';
import { VehicleInfo } from '@/pages/TripsPage/interfaces/vehicleInfo.interface';
import ImageViewDialog from './components/ImageViewDialog/ImageViewDialog.vue';

@Component({
  components: { ImageViewDialog, DialogWindow },
})
export default class DocumentsDialog extends DialogFormClass {
  @Prop({ required: false })
  private readonly driverInfo: DriverInfo | undefined;

  @Prop({ required: false })
  private readonly vehicleInfo: VehicleInfo | undefined;

  private selectedDocumentName = '';
  private selectedDocumentUrl = '';

  private driverDocuments: {
    title: string;
    photoUrl?: string;
    expireDate?: number | null;
  }[] | undefined;

  private vehicleDocuments: {
    title: string;
    photoUrl?: string;
    expireDate?: number | null;
  }[] | undefined;

  protected dialogOpened() {
    if (this.driverInfo) {
      this.driverDocuments = [];
      this.driverDocuments.push(
        {
          title: 'Driver ID front',
          photoUrl: this.driverInfo?.idFrontPhoto,
          expireDate: null,
        },
        {
          title: 'Driver ID back',
          photoUrl: this.driverInfo?.idBackPhoto,
          expireDate: null,
        },
        {
          title: 'Driver license',
          photoUrl: this.driverInfo?.driverLicensePhoto,
          expireDate: this.driverInfo?.dtLicenseExpire,
        },
        {
          title: 'Health report',
          photoUrl: this.driverInfo?.healthReportPhoto,
          expireDate: this.driverInfo?.dtHealthReportExpire,
        },
        {
          title: 'Police report',
          photoUrl: this.driverInfo?.policeReportPhoto,
          expireDate: null,
        },
        {
          title: 'GS Certificate',
          photoUrl: this.driverInfo?.gsCertificatePhoto,
          expireDate: null,
        },
        {
          title: 'ACV Pass',
          photoUrl: this.driverInfo?.acvPassPhoto,
          expireDate: null,
        },
        {
          title: 'Port permit',
          photoUrl: this.driverInfo?.portPermitPhoto,
          expireDate: null,
        },
      );
    } else {
      this.vehicleDocuments = [];
      this.vehicleDocuments.push(
        {
          title: 'Vehicle registration certificate',
          photoUrl: this.vehicleInfo?.vehicleRegistrationCertificatePhoto,
          expireDate: null,
        },
        {
          title: 'Vehicle license',
          photoUrl: this.vehicleInfo?.vehicleLicensePhoto,
          expireDate: null,
        },
        {
          title: 'Insurance',
          photoUrl: this.vehicleInfo?.insurancePhoto,
          expireDate: null,
        },
        {
          title: 'Emission report',
          photoUrl: this.vehicleInfo?.emissionReportPhoto,
          expireDate: null,
        },
        {
          title: 'Inspection report',
          photoUrl: this.vehicleInfo?.inspectionReportPhoto,
          expireDate: null,
        },
        {
          title: 'Service record',
          photoUrl: this.vehicleInfo?.serviceRecordPhoto,
          expireDate: null,
        },
        {
          title: 'ACV Pass',
          photoUrl: this.vehicleInfo?.acvPassPhoto,
          expireDate: null,
        },
        {
          title: 'Port permit',
          photoUrl: this.vehicleInfo?.portPermitPhoto,
          expireDate: null,
        },
      );
    }
  }

  private openDocumentImage(documentName: string, url: string) {
    this.selectedDocumentName = documentName;
    this.selectedDocumentUrl = url;
    // @ts-ignore
    this.$refs.imageDialog.open();
  }
}
