import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AppDrawer, AppMenu, ToasterNotification } from '@/components';
import { UserInfo } from '@/store';
import { setUserInfoFromJWT } from '@/core';
import { UserModel, UserRule } from '@/core/enums';
import { OrganizationItem, OrganizationService } from '@/services';
import { useFavicon } from '@vueuse/core';

const sharedStore = namespace('Shared');
const DistributorStore = namespace('Distributor');

@Component({
  components: { ToasterNotification, AppMenu, AppDrawer },
})
export default class App extends Vue {
  @sharedStore.State
  private readonly userInfo!: UserInfo | null;

  @sharedStore.Mutation
  private readonly setUserInfo!: (userInfo: UserInfo | null) => void;

  @sharedStore.Mutation
  private readonly setIsOrganizationSettingsDialogOpen!: (isOpen: boolean) => void

  @sharedStore.Mutation
  private readonly setIsUserSettingsDialogOpen!: (isOpen: boolean) => void

  @DistributorStore.Mutation
  private readonly setOrganizations!: (organizationItem: OrganizationItem | null) => void;

  @DistributorStore.State
  private readonly organizations!: OrganizationItem | null;

  public isDrawerOpen = true;
  public isDrawerShow = true;
  public isNormalDomain = false;

  public icon = useFavicon()

  public logout(): void {
    localStorage.removeItem('localization');
    localStorage.removeItem('OrderTableHeaders');
    localStorage.removeItem('OrderTableHeadersForStep2');
    localStorage.removeItem('OrderItems');
    localStorage.removeItem('widgetLayout');
    localStorage.removeItem('VehicleTableHeaders');

    const loginAsHierarchy = localStorage.getItem('loginAsHierarchy');
    if (loginAsHierarchy) {
      const hierarchy = JSON.parse(loginAsHierarchy) as string[];
      const accessToken = hierarchy[hierarchy.length - 1];
      setUserInfoFromJWT(accessToken);

      hierarchy.pop();
      if (hierarchy.length === 0) {
        localStorage.removeItem('loginAsHierarchy');
      } else {
        localStorage.setItem('loginAsHierarchy', JSON.stringify(hierarchy));
      }

      localStorage.setItem('accessToken', accessToken);
    } else {
      localStorage.removeItem('accessToken');

      this.setUserInfo(null);

      document.location.reload();
    }
  }

  public async mounted() {
    const DefaultTitle = document.getElementById('title');
    // @ts-ignore
    DefaultTitle.innerText = 'Eport';

    this.isNormalDomain = window.location.hostname === 'eport.kloudip.com' || window.location.hostname === 'deveport.kloudip.com' || window.location.hostname === 'localhost';

    if (!this.isNormalDomain) {
      const data = await OrganizationService.findDistInfo(window.location.hostname);
      this.setOrganizations(data);
      const favicon = document.getElementById('favicon');
      const title = document.getElementById('title');
      // @ts-ignore
      title.innerText = data?.pageTitle;
      // @ts-ignore
      favicon.href = data?.pageFavicon;
    }
    const localization = localStorage.getItem('localization');
    if (localization) {
      this.$i18n.locale = localization;
    }
    this.routeChanged();
  }

  public toggleDrawer(data:boolean) {
    this.isDrawerOpen = data;
  }
  @Watch('$route')
  public routeChanged(): void {
    this.isDrawerShow = this.$route.fullPath !== '/';
  }

  get showOrganizationSettings(): boolean {
    if (this.userInfo) {
      return !!this.userInfo.rules.find(r => r.model === UserModel.ORGANIZATION_OWNER && r.rule === UserRule.WRITE);
    }
    return false;
  }
}
