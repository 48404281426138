import { currentDate, HTTP } from '@/core';
import { TripBillItem, UpdateTripBillRequest } from './interface';

export class TripBillService {
  static async find(tripId: string): Promise<TripBillItem> {
    const { data } = await HTTP.get(`trips/${tripId}/bill`);

    return data;
  }

  static async update(tripId: string, bill: UpdateTripBillRequest): Promise<TripBillItem> {
    const { data } = await HTTP.patch(`trips/${tripId}/bill`, bill);

    return data;
  }

  static async submit(tripId: string): Promise<void> {
    await HTTP.patch(`trips/${tripId}/bill/submit`);
  }

  static async approve(tripId: string): Promise<void> {
    await HTTP.patch(`trips/${tripId}/bill/approve`);
  }

  static async sendEmail(tripId: string): Promise<void> {
    await HTTP.post(`trips/${tripId}/bill/send-email`);
  }

  static async export(tripId: string): Promise<void> {
    const { data } = await HTTP.get(`trips/${tripId}/bill/export`, { responseType: 'blob' });

    const fileURL = window.URL.createObjectURL(new Blob([data]));
    const fileLink = document.createElement('a');

    const name = `${currentDate()}`;

    fileLink.href = fileURL;
    fileLink.setAttribute('download', `${name}.pdf`);
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  }
}
