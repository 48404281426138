export enum OrganizationStatus {
  REGISTERED = 0,
  ACTIVE = 1,
  BLOCKED = 2,
}
/**
 * Use for update organization status
 */
export enum UpdateOrganizationStatus {
  ACTIVE = 1,
  BLOCKED = 2,
}
