export enum UserModel {
  USER = 'user',
  ORGANIZATIONS = 'organizations',
  CONTACT = 'contact',
  LOCATION = 'location',
  VEHICLE = 'vehicle',
  SHARE_VEHICLE = 'share vehicle',
  ORGANIZATION_OWNER = 'organization owner',
  GEO = 'geo',
  PARTNER = 'partner',
  OPERATION = 'operation',
  CONTRACT = 'contract',
  ORDER_TYPE = 'order type',
  CARGO = 'cargo',
  ORDER = 'order',
  TRIP = 'trip',
  REPORT = 'report',
  ROUTE_ZONE = 'route zone',
  DASHBOARD = 'dashboard',
  ROUTE = 'route',
  DRIVER = 'driver',
}
