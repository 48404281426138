import { NavigationGuardNext, Route } from 'vue-router';
import { UserInfo } from '@/store/modules/shared/interfaces';
import store from '@/store';
import { setUserInfoFromJWT } from '@/core';

export function isAlreadyAuth(to: Route, from: Route, next: NavigationGuardNext) {
  const stateUser: UserInfo = store.state.Shared.userInfo;

  if (stateUser) {
    next({ path: '/' });
    return;
  }

  const accessToken = localStorage.getItem('accessToken');
  if (!stateUser && accessToken) {
    setUserInfoFromJWT(accessToken);

    next({ path: '/' });
    return;
  }

  next();
}
