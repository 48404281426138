import Vue from 'vue';
import dayjs from 'dayjs';
import i18n from '@/plugins/i18n';

Vue.filter('date', (date: number, format = 'DD.MM.YYYY') => {
  if (date.toString().length < 10) {
    return i18n.tc('general.filters.invalidDateText');
  }

  // Hack for transform unix time in unix timestamp (server can send both the unix and the unix timestamp)
  if (date.toString().length === 10) {
    return dayjs(date * 1000).format(format);
  }

  return dayjs(date).format(format);
});
