import { Component, Emit } from 'vue-property-decorator';
import { DialogFormClass } from '@/core';
import { DialogWindow } from '@/components';

@Component({
  components: { DialogWindow },
})
export default class RejectDialog extends DialogFormClass {
  public formData = {
    rejectReason: '',
  }

  @Emit('rejected')
  public reject(): string {
    this.close();

    return this.formData.rejectReason;
  }
}
