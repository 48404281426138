import { Component } from 'vue-property-decorator';
import { DialogClass } from '@/core';

@Component
export default class FullImageView extends DialogClass {
  public photoUrl: string | null = null;

  protected dialogOpened(photo: string): void {
    this.photoUrl = photo;
  }
}
