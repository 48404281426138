import Vue from 'vue';
import 'vue-class-component/hooks';
import i18n from '@/plugins/i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/vueMask';
import './plugins/leaflet';
import './plugins/vueCurrencyFilter';
import './plugins/vueApexCharts';
import './core/filters/date';
import './core/filters/toKm';
import './core/filters/duration';
import './core/filters/duration-custom';
import './styles.scss';

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
