import { HTTP, Pagination } from '@/core';
import { ContractItem, FindAllOptions, StoreContractRequest, ReviewContractRequest } from './interface';

export class ContractsService {
  static async store(contract: StoreContractRequest): Promise<ContractItem> {
    const { data } = await HTTP.post('contracts', contract);

    return data;
  }

  static async findAll(params: FindAllOptions): Promise<Pagination<ContractItem>> {
    const { data } = await HTTP.get('contracts', { params });

    return data;
  }

  static async findOne(contractId: string): Promise<ContractItem> {
    const { data } = await HTTP.get(`contracts/${contractId}`);

    return data;
  }

  static async update(contractId: string, contract: Partial<StoreContractRequest>): Promise<ContractItem> {
    const { data } = await HTTP.patch(`contracts/${contractId}`, contract);

    return data;
  }

  static async review(contractId: string, contract: ReviewContractRequest): Promise<void> {
    await HTTP.patch(`contracts/${contractId}/review`, contract);
  }

  static async delete(contractId: string): Promise<void> {
    await HTTP.delete(`contracts/${contractId}`);
  }
}
