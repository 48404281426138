import { HTTP } from '@/core';
import { VehicleFileItem } from './interface/vehicleFileItem.interface';
import { StoreVehicleFile } from './interface/storeVehicleFile.interface';

export class VehicleFileService {
  static async store(vehicleId: string, payload: StoreVehicleFile): Promise<VehicleFileItem> {
    const { data } = await HTTP.post(`vehicles/${vehicleId}/files`, payload);

    return data;
  }

  static async storeDrafted(vehicleId: string, payload: StoreVehicleFile): Promise<VehicleFileItem> {
    const { data } = await HTTP.post(`vehicles/draft/${vehicleId}/file`, payload);

    return data;
  }

  static async delete(vehicleId: string, fileId: string): Promise<void> {
    await HTTP.delete(`vehicles/${vehicleId}/files/${fileId}`);
  }
}
