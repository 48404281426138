import { HTTP } from '@/core';
import { GetRouteResponse, GetRouteDistanceResponse, SearchAddressItem } from './interface';

export class GeoService {
  static async searchAddress(address: string): Promise<SearchAddressItem[]> {
    const { data } = await HTTP.get('shared/geo/address/search', { params: { address } });

    return data;
  }

  static async getAddress(position: [number, number]): Promise<string | null> {
    const { data } = await HTTP.get('shared/geo/address', { params: { position: position.join(',') } });

    return data.address;
  }

  static async getRoute(points: [number, number][], excludeTolls?: boolean): Promise<string> {
    const payload: { points: string; excludeTolls?: boolean } = {
      points: JSON.stringify(points),
    };

    if (excludeTolls !== undefined) {
      payload.excludeTolls = excludeTolls;
    }

    const { data } = await HTTP
      .get<GetRouteResponse>('shared/geo/route', { params: payload });

    return data.points;
  }

  static async getRouteDistance(points: [number, number][]): Promise<number> {
    const { data } = await HTTP
      .get<GetRouteDistanceResponse>('shared/geo/distance', { params: { points: JSON.stringify(points) } });

    return data.distance;
  }
}
