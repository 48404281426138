import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { OrganizationItem, OrganizationService } from '@/services';
import { FormControlClass } from '@/core';

const organizationStore = namespace('Organization');

@Component
export default class OrganizationSelect extends FormControlClass<string> {
  @organizationStore.Mutation
  private readonly setOrganizations!: (organizations: OrganizationItem[]) => void;

  public async mounted(): Promise<void> {
    try {
      this.isLoading = true;

      const { data } = await OrganizationService.findAll({ page: 1, limit: 9999 });

      this.setOrganizations(data as OrganizationItem[]); // TODO change functionality now we use base item

      this.items = data.map(o => ({ value: o.id, text: o.name }));
    } finally {
      this.isLoading = false;
    }
  }
}
