import { Component, Prop, Watch } from 'vue-property-decorator';
import { UserItemBase, UserService } from '@/services';
import { FormControlClass } from '@/core';

@Component
export default class UserSelect extends FormControlClass<string> {
  @Prop()
  private readonly driversOrganizationId!: string | undefined;

  public async setData(): Promise<void> {
    try {
      this.isLoading = true;

      let users: UserItemBase[];
      if (this.driversOrganizationId) {
        users = await UserService.findAllDrivers(this.driversOrganizationId);
      } else {
        const { data } = await UserService.findAll({ page: 1, limit: 9999 });
        users = data;
      }

      this.items = users.map(u => ({ value: u.id, text: u.login }));
    } finally {
      this.isLoading = false;
    }
  }

  @Watch('driversOrganizationId')
  private driversOrganizationIdChanged(): void {
    this.setData();
  }
}
