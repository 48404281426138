import { HTTP } from '@/core';
import { DictionaryItem } from './interface';

export class VehicleDictionaryService {
  static async store(dictionaryUrl: string, name: string): Promise<DictionaryItem> {
    const { data } = await HTTP.post(`vehicles/${dictionaryUrl}`, { name });

    return data;
  }

  static async findAll(dictionaryUrl: string): Promise<DictionaryItem[]> {
    const { data } = await HTTP.get(`vehicles/${dictionaryUrl}`);

    return data;
  }

  static async update(dictionaryUrl: string, id: string, name: string): Promise<DictionaryItem> {
    const { data } = await HTTP.patch(`vehicles/${dictionaryUrl}/${id}`, { name });

    return data;
  }

  static async delete(dictionaryUrl: string, id: string): Promise<void> {
    await HTTP.delete(`vehicles/${dictionaryUrl}/${id}`);
  }
}
