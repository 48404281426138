import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { UserRole } from '@/core/enums/userRole.enum';
import { ConfirmDialog, ConfirmDialogOptions, Notification, UserInfo } from './interfaces';

@Module({ namespaced: true })
class Shared extends VuexModule {
  public notification: Notification = {
    type: 'success',
    timeout: 3000,
    position: 'right',
    text: '',
  };
  public userInfo: UserInfo | null = null;
  public confirmDialog: ConfirmDialog | null = null;
  public isOrganizationSettingsDialogOpen = false;
  public isUserSettingsDialogOpen = false;

  get userRole(): UserRole | undefined {
    return this.userInfo?.role;
  }

  @Mutation
  public setNotification({ type = 'success', timeout = 3000, text, position }: Notification): void {
    this.notification = {
      type,
      timeout,
      text,
      position: position || 'right',
    };
  }

  @Mutation
  public setUserInfo(user: UserInfo | null): void {
    this.userInfo = user;
  }

  @Mutation
  public setConfirmDialog(dialog: ConfirmDialog): void {
    this.confirmDialog = dialog;
  }

  @Mutation
  public setIsOrganizationSettingsDialogOpen(isOpen: boolean): void {
    this.isOrganizationSettingsDialogOpen = isOpen;
  }

  @Mutation
  public setIsUserSettingsDialogOpen(isOpen: boolean): void {
    this.isUserSettingsDialogOpen = isOpen;
  }

  @Action
  public async confirm(options: ConfirmDialogOptions): Promise<boolean> {
    if (this.confirmDialog) {
      return this.confirmDialog.open(options);
    }

    throw Error('Confirm dialog not init.');
  }
}

export default Shared;
