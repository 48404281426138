export function paramsToNull<T>(params: T): T {
  if (typeof params !== 'object') return params;

  const val = { ...params };
  Object.keys(val).forEach(key => {
    // @ts-ignore
    if (val[key as keyof typeof params] === '') {
      // @ts-ignore
      val[key] = null;
    }
  });

  return val;
}
