export function fileValidation({ file, ...options }: FileValidationOptions): string[] {
  const validationErrors: string[] = [];

  if (options.availableTypes && !options.availableTypes.includes(file.type as FileTypes)) {
    validationErrors.push(`File has invalid format. Only ${options.availableTypes.join(',')} supported`);
  }

  if (options.maxFileSize && file.size / 1024 / 1024 > options.maxFileSize) {
    validationErrors.push(`Max file size. Max ${options.maxFileSize} Mb`);
  }

  return validationErrors;
}

export async function getFileBase64(file: File): Promise<string> {
  return new Promise(r => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (!reader.result) return;

      const base64String = (reader.result as string)
        .replace('data:', '')
        .replace(/^.+,/, '');

      r(base64String);
    };
    reader.readAsDataURL(file);
  });
}

/**
 * maxFileSize number in MB
 */
interface FileValidationOptions {
  file: File,
  availableTypes?: FileTypes[];
  maxFileSize?: number;
}

export type FileTypes = 'image/png' | 'image/jpeg' | 'application/pdf';
