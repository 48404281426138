import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { FormControlClass } from '@/core';

@Component
export default class DecimalInput extends FormControlClass<string> {
  @Prop({ default: 2 })
  decimalCount!: number;

  public localValue = '0.00';

  public mounted(): void {
    this.numberChanged(this.value.toString());
  }

  @Watch('value')
  private inputValueChanged(): void {
    this.numberChanged(this.value.toString());
  }

  @Emit('input')
  public numberChanged(val: string): number {
    this.localValue = val;
    if (isNaN(+val)) {
      this.localValue = `0.${'0'.repeat(this.decimalCount)}`;
      return +this.localValue;
    }

    let value: string = val;
    if (val.includes('e')) {
      const arr = val.replace('.', '').split('e-');

      value = `0.${'0'.repeat(+arr[1] - 1)}${arr[0]}`;
    }

    if (+val === 0) {
      value = `0.${'0'.repeat(this.decimalCount)}`;
    }

    setTimeout(() => {
      this.localValue = value;
    }, 50);

    return +value;
  }
}
