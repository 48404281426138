import { baseHTTP, HTTP, Pagination, paramsToNull } from '@/core';
import { UpdateShortOrganizationRequest } from '@/services/organization/interface/updateOrganizationRequest.interface';
import {
  OrganizationItem,
  OrganizationItemBase,
  OrganizationSearchOptions,
  StoreOrganizationRequest,
  UpdateOrganizationRequest,
} from './interface';

export default class OrganizationService {
  static async register(organization: StoreOrganizationRequest): Promise<void> {
    await baseHTTP.post('organizations/registration', organization);
  }

  static async store(organization: StoreOrganizationRequest): Promise<OrganizationItem> {
    const { data } = await HTTP.post('organizations', paramsToNull(organization));

    return data;
  }
  static async findDistInfo(domain: string): Promise<OrganizationItem> {
    const { data } = await HTTP.get(`organizations/distributor?domain=${domain}`);
    return data;
  }

  static async findAll(params?: OrganizationSearchOptions): Promise<Pagination<OrganizationItemBase>> {
    const { data } = await HTTP.get('organizations', { params });

    return data;
  }

  static async findOne(id: string): Promise<OrganizationItem> {
    const { data } = await HTTP.get(`organizations/${id}`);

    return data;
  }

  static async getUserOrganization(): Promise<OrganizationItem> {
    const { data } = await HTTP.get('organizations/my');

    return data;
  }

  static async update(
    organizationId: string,
    organization: UpdateOrganizationRequest,
  ): Promise<OrganizationItem | void> {
    const { data } = await HTTP.patch(`organizations/${organizationId}`, paramsToNull(organization));

    return data;
  }

  static async selfUpdate(organization: UpdateShortOrganizationRequest): Promise<OrganizationItem> {
    const { data } = await HTTP.patch('organizations/self', paramsToNull(organization));

    return data;
  }

  static async uploadLogo(organizationId: string, logo: string): Promise<void> {
    await HTTP.post(`organizations/${organizationId}/logo`, { logo });
  }

  static async mergeChanges(changesId: string): Promise<void> {
    await HTTP.patch(`organizations/changes/${changesId}`);
  }

  static async declineChanges(changesId: string): Promise<void> {
    await HTTP.delete(`organizations/changes/${changesId}`);
  }

  static async delete(organizationId: string): Promise<void> {
    await HTTP.delete(`organizations/${organizationId}`);
  }

  static async getEPCCount(): Promise<number> {
    const { data } = await HTTP.get('v1/organizations/epc');

    return data.EPC;
  }
}
