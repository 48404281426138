import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SideDialogWindow extends Vue {
  @Prop({ default: 400 })
  public readonly width!: number | string;

  @Prop({ default: false })
  public readonly eager!: boolean;

  @Prop({ default: true })
  public readonly showButtons!: boolean;

  @Prop({ default: false })
  public readonly isOpen!: boolean;

  @Prop({ default: '' })
  public readonly title!: string;

  @Prop({ default: false })
  public readonly isLoading!: boolean;

  @Prop({ default: false })
  public readonly isSubmitBtnDisabled!: boolean;

  @Prop({ default: 'save' })
  public readonly submitBtnType!: 'save' | 'saveChanges' | 'update';

  @Prop({ default: '' })
  public readonly customSubmitBtnText!: string;

  @Prop({ default: '' })
  public readonly customCancelBTnText!: string;

  @Prop({ default: true })
  public readonly showSubmitBtn!: boolean;

  @Prop({ default: false })
  public readonly showSkeletonLoader!: boolean;
}
