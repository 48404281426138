import { baseHTTP } from '@/core';
import { CurrencyItem } from './interface';

export default class CurrencyService {
  static async findAll(): Promise<CurrencyItem[]> {
    const { data } = await baseHTTP.get('shared/currencies');

    return data;
  }
}
