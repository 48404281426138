import { VuexModule, Module, Mutation } from 'vuex-module-decorators';
import { VehicleMarkerItem, NavigationData } from './interfaces';

@Module({ namespaced: true })
export class VehicleMonitoring extends VuexModule {
  public vehiclesMarkersItems: VehicleMarkerItem[] = [];

  @Mutation
  public unsetVehiclesMarkers(): void {
    this.vehiclesMarkersItems = [];
  }

  @Mutation
  public addVehicleMarker(marker: VehicleMarkerItem): void {
    this.vehiclesMarkersItems.push(marker);
  }

  @Mutation
  public updateVehicleMarker({ deviceId, value }: NavigationData): void {
    this.vehiclesMarkersItems = this.vehiclesMarkersItems.map(m => {
      if (m.deviceId === deviceId) {
        return {
          ...m,
          deviceId,
          position: [value.latitude, value.longitude],
          direction: value.direction,
          lastUpdateTime: +(Date.now() / 1000).toFixed(0),
        };
      }

      return m;
    });
  }

  @Mutation
  public removeVehicleMarker(deviceId: number): void {
    this.vehiclesMarkersItems = this.vehiclesMarkersItems.filter(m => m.deviceId !== deviceId);
  }
}
