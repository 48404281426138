import { HTTP, Pagination, PaginationOptions } from '@/core';
import { RouteZoneItem } from './interface';

export class RouteZoneService {
  static async store(routeZone: Omit<RouteZoneItem, 'id'>): Promise<RouteZoneItem> {
    const { data } = await HTTP.post('routes-zones', routeZone);

    return data;
  }

  static async findAll(params: PaginationOptions): Promise<Pagination<RouteZoneItem>> {
    const { data } = await HTTP.get('routes-zones', { params });

    return data;
  }

  static async update(addressId: string, address: Partial<Omit<RouteZoneItem, 'id'>>): Promise<RouteZoneItem> {
    const { data } = await HTTP.patch(`routes-zones/${addressId}`, address);

    return data;
  }

  static async delete(addressId: string): Promise<void> {
    await HTTP.delete(`routes-zones/${addressId}`);
  }
}
