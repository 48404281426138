import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationResult } from '../utils/validator';
import { VuetifySelectItem } from '../interfaces/vuetifySelectItem.interface';
/**
 * Wrapper for vuetify elements.
 */
@Component
export default class FormControlClass<T, K = unknown> extends Vue {
  @Prop({ default: '' })
  public readonly value!: T;

  @Prop({ default: '' })
  public readonly label!: string;

  @Prop({ default: '' })
  public readonly dense!:string

  @Prop({ default: () => [] })
  public readonly rules!: ValidationResult[];

  @Prop({ default: false })
  public readonly hideDetails!: boolean;

  @Prop({ default: true })
  public readonly outlined!: boolean;

  @Prop()
  public readonly color: string | undefined;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Prop({ default: false })
  public readonly multiple!: boolean;

  @Prop({ default: false })
  public readonly clearable!: boolean;

  @Prop({ default: false })
  public readonly readonly!: boolean;

  @Prop()
  public readonly customFilter: (() => void) | undefined;

  @Prop()
  public readonly selectedItem: VuetifySelectItem<T> | string | undefined;

  public isLoading = false;
  public originalItems: K[] = [];
  public items: VuetifySelectItem<T>[] | string[] = [];

  public mounted() {
    this.setData();
  }
  // TODO add original items params for avoid unnecessary backend call and change all components
  //  for getting data from new method setData
  @Watch('customFilter')
  private customFilterChanged() {
    this.setData();
  }

  @Watch('selectedItem')
  private selectedItemChanged() {
    if (!this.selectedItem) return;
    // @ts-ignore
    this.items = [this.selectedItem];
    // @ts-ignore
    this.valueChanged(typeof this.selectedItem === 'string' ? this.selectedItem : this.selectedItem.value);
  }

  @Emit('input')
  public valueChanged(value: T | T[]): T | T[] {
    return value;
  }
  /**
   * User for inform parents that async data from server loaded
   * Good place for use after set data
   */
  @Emit('dataLoaded')
  protected dataLoaded(): boolean {
    return true;
  }
  /**
   * Put your logic of setting data here if needed
   * Good place for set items for select boxed
   */
  protected async setData(): Promise<void> {
    // Put your logic of setting items data here
  }
}
