import { HTTP, Pagination, PaginationOptions } from '@/core';
import { VehicleScheduleItem } from '@/services/vehicle/interface';
import { AvailableVehicleItem, SharedVehicleItem, ShareVehicle, FindAllOptions } from './interface';

export class SharedVehicleService {
  static async store(shareVehicle: ShareVehicle): Promise<SharedVehicleItem> {
    const { data } = await HTTP.post('vehicles/share', shareVehicle);

    return data;
  }

  static async findAll(params: PaginationOptions): Promise<Pagination<SharedVehicleItem>> {
    const { data } = await HTTP.get('vehicles/share', { params });

    return data;
  }

  static async findAllAvailable(params: FindAllOptions, withDriver?: boolean):
    Promise<Pagination<AvailableVehicleItem>> {
    const { data } = await HTTP.get('vehicles/share/available', {
      params: {
        ...params,
        withDriver,
      },
    });

    return data;
  }

  static async delete(relationId: string): Promise<void> {
    await HTTP.delete(`vehicles/share/${relationId}`);
  }

  static async getSchedule(): Promise<VehicleScheduleItem[]> {
    const { data } = await HTTP.get('vehicles/share/schedule');

    return data;
  }
}
