import { HTTP, Pagination } from '@/core';
import { ContactItem, PaginationOptions, StoreContactRequest } from './interface';

export class ContactsService {
  static async store(contact: StoreContactRequest): Promise<ContactItem> {
    const { data } = await HTTP.post('contacts', contact);

    return data;
  }

  static async findAll(params: PaginationOptions): Promise<Pagination<ContactItem>> {
    const { data } = await HTTP.get('contacts', { params });

    return data;
  }

  static async update(contactId: string, contact: StoreContactRequest): Promise<ContactItem> {
    const { data } = await HTTP.patch(`contacts/${contactId}`, contact);

    return data;
  }

  static async delete(contactId: string): Promise<void> {
    await HTTP.delete(`contacts/${contactId}`);
  }
}
