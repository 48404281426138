import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Notification } from '@/store';

const sharedStore = namespace('Shared');

@Component
export default class ToasterNotification extends Vue {
  @sharedStore.State public notification!: Notification;

  public isOpen = false;

  @Watch('notification', { deep: true })
  notificationChanged(): void {
    this.isOpen = true;
  }
}
