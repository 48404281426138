import { currentDate, HTTP } from '@/core';
import { ReportName } from '@/services/reports/enum';
import { ReportExportOptions, ReportOptions } from './interface';

export class ReportsService {
  static async generate(settings: ReportOptions): Promise<any> {
    if (settings.reportName === ReportName.tripLocationDetailReport) {
      const { data } = await HTTP.get('reports/trip-location-detail', { params: settings });

      return data;
    }

    if (settings.reportName === ReportName.vehicleComplianceReport) {
      const { data } = await HTTP.get('reports/vehicle-compliance', { params: settings });

      return data;
    }
    if (settings.reportName === ReportName.configurableReport) {
      const { data } = await HTTP.get('reports/trips-details', { params: settings });

      return data;
    }
    if (settings.reportName === ReportName.pickingSlipReport) {
      const { data } = await HTTP.get(`reports/picking-slip?tripId=${settings.tripId}`);

      return data;
    }

    const { data } = await HTTP.get('reports', { params: settings });

    return data;
  }
  static async tripDetail(settings: ReportOptions): Promise<any> {
    const { data } = await HTTP.get('reports/trips-details', { params: settings });

    return data;
  }
  static async OrderDetail(settings: ReportOptions): Promise<any> {
    const { data } = await HTTP.get('reports/orders-details', { params: settings });

    return data;
  }

  static async export(settings: ReportExportOptions): Promise<void> {
    const { data } = await HTTP.get('reports/export', { params: settings, responseType: 'blob' });

    const fileURL = window.URL.createObjectURL(new Blob([data]));
    const fileLink = document.createElement('a');

    const name = `${currentDate()}-${settings.reportName}`;

    fileLink.href = fileURL;
    fileLink.setAttribute('download', `${name}.xlsx`);
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  }

  static async exportTripLocationDetail(tripId: string): Promise<void> {
    const { data } = await HTTP.get('reports/export/trip-location-detail', {
      params: { tripId },
      responseType: 'blob',
    });

    const fileURL = window.URL.createObjectURL(new Blob([data]));
    const fileLink = document.createElement('a');

    const name = `${currentDate()}-trip-location-detail`;

    fileLink.href = fileURL;
    fileLink.setAttribute('download', `${name}.xlsx`);
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  }

  static async exportVehicleCompliance(ownerId: string): Promise<void> {
    const { data } = await HTTP.get('reports/export/vehicle-compliance', {
      params: { ownerId },
      responseType: 'blob',
    });

    const fileURL = window.URL.createObjectURL(new Blob([data]));
    const fileLink = document.createElement('a');

    const name = `${currentDate()}-vehicle-compliance`;

    fileLink.href = fileURL;
    fileLink.setAttribute('download', `${name}.xlsx`);
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  }
  static async exportPickingSlip(tripId:string, reportName:string): Promise<void> {
    const { data } = await HTTP.get(`reports/export/picking-slip?tripId=${tripId}`, { responseType: 'blob' });

    const fileURL = window.URL.createObjectURL(new Blob([data]));
    const fileLink = document.createElement('a');

    const name = `${currentDate()}-${reportName}`;

    fileLink.href = fileURL;
    fileLink.setAttribute('download', `${name}.xlsx`);
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  }

  static async exportConfigurableReport(settings: ReportExportOptions, reportName: string): Promise<void> {
    const { data } = await HTTP.get('reports/export/trips-details?', { params: settings, responseType: 'blob' });

    const fileURL = window.URL.createObjectURL(new Blob([data]));
    const fileLink = document.createElement('a');

    const name = `${currentDate()}-${reportName}`;

    fileLink.href = fileURL;
    fileLink.setAttribute('download', `${name}.xlsx`);
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  }

  static async exportConfigurableOrderReport(settings: ReportExportOptions, reportName: string): Promise<void> {
    const { data } = await HTTP.get('reports/export/orders-details?', { params: settings, responseType: 'blob' });

    const fileURL = window.URL.createObjectURL(new Blob([data]));
    const fileLink = document.createElement('a');

    const name = `${currentDate()}-${reportName}`;

    fileLink.href = fileURL;
    fileLink.setAttribute('download', `${name}.xlsx`);
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  }
}
