import { Component } from 'vue-property-decorator';
import { CurrencyService } from '@/services';
import { FormControlClass } from '@/core';
import { CountrySelect } from '@/components';

@Component({
  components: { CountrySelect },
})
export default class CurrencySelect extends FormControlClass<string> {
  public async mounted(): Promise<void> {
    try {
      this.isLoading = true;

      const currencies = await CurrencyService.findAll();

      this.items = currencies.map(c => ({ value: c.id, text: `${c.fullName} - ${c.shortName}` }));
    } finally {
      this.isLoading = false;
    }
  }
}
