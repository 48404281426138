import { Component, Prop, Watch } from 'vue-property-decorator';
import { LocationItem, LocationsService } from '@/services';
import { FormControlClass } from '@/core';
import { FindAllOptions } from '@/services/locations/interface';

@Component
export default class LocationSelect extends FormControlClass<string, LocationItem> {
  @Prop()
  private readonly contactId?: string | undefined;

  @Prop()
  private readonly withContacts?: boolean | undefined;

  @Prop()
  private readonly organizationContactId?: string | undefined;

  @Watch('contactId')
  private async contactIdChanged(): Promise<void> {
    this.setData();
  }

  public limit = 20;

  protected async setData(): Promise<void> {
    try {
      this.isLoading = true;

      const options: FindAllOptions = {
        page: 1,
        limit: 9999,
      };

      if (this.contactId) {
        options.contactId = this.contactId;
      }

      if (this.withContacts) {
        options.withContacts = true;
      }

      if (this.organizationContactId) {
        options.organizationContactId = this.organizationContactId;
      }

      const { data } = await LocationsService.findAll(options);
      this.originalItems = data;

      this.items = data.map(l => ({ value: l.id, text: l.name }));
    } finally {
      this.isLoading = false;
    }
  }

  public async loadMore() {
    this.limit += 20;
    await this.setData();
  }

  // @ts-ignore
  public valueChanged(value: string | string[]) {
    this.$emit('input', value);
    this.$emit('location', this.originalItems.find(i => i.id === value));
  }
}
