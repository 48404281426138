import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PaginationComponent extends Vue {
  @Prop({ required: true })
  public totalPages!: number;

  public selectedPage = 1;

  get displayedPages(): number[] {
    const firstFivePages = [];
    for (let i = 1; i <= Math.min(this.totalPages, 5); i += 1) {
      firstFivePages.push(i);
    }
    return firstFivePages;
  }

  @Emit('goToPage')
  public goToPage(newPage: number) {
    this.selectedPage = newPage;
    this.$emit('update:selectedPage', newPage);
  }
}
