import { HTTP, Pagination } from '@/core';
import { OrganizationItem, OrganizationSearchOptions, StoreOrganizationRequest } from '../organization/interface';
import { OrganizationPartnerItem } from './interface';

export default class OrganizationPartnersService {
  static async store(organization: StoreOrganizationRequest): Promise<OrganizationItem> {
    const { data } = await HTTP.post('partners', organization);

    return data;
  }

  static async add(partnerId: string): Promise<OrganizationPartnerItem> {
    const { data } = await HTTP.post('partners/add', { partnerId });

    return data;
  }

  static async findAll(params?: OrganizationSearchOptions): Promise<Pagination<OrganizationPartnerItem>> {
    const { data } = await HTTP.get('partners', { params });

    return data;
  }

  static async find(
    organizationId: string,
    params?: OrganizationSearchOptions,
  ): Promise<Pagination<OrganizationPartnerItem>> {
    const { data } = await HTTP.get(`partners/${organizationId}`, { params });

    return data;
  }

  static async confirm(partnershipId: string): Promise<void> {
    await HTTP.patch(`partners/${partnershipId}/confirm`);
  }

  static async delete(organizationId: string): Promise<void> {
    await HTTP.delete(`partners/${organizationId}`);
  }
}
