import { HTTP } from '@/core';
import { UserRuleItem } from './interface/userRuleItem.interface';

export class UserRuleService {
  static async findAll(): Promise<UserRuleItem[]> {
    const { data } = await HTTP.get('users/rules');

    return data;
  }
}
