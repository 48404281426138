import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './locales/en.json';
import it from './locales/it.json';
import fr from './locales/fr.json';
import sp from './locales/sp.json';
import sin from './locales/sin.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en, it, fr, sp, sin },
});
