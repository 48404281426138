import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AlertComponent extends Vue {
  @Prop({ required: true })
  public text : string| undefined;

  @Prop({ required: true })
  public icon : string| undefined;

  @Prop({ default: false })
  public closable : boolean| undefined;

  public isOpen = true;
}
