import axios from 'axios';
import { showErrorNotification } from './errorHandler';

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL,
});

HTTP.interceptors.request.use((config) => {
  const confCopy = { ...config };

  if (!confCopy.headers) {
    confCopy.headers = {};
  }

  confCopy.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

  return confCopy;
});

HTTP.interceptors.response.use(response => response, e => {
  if (e.response.data.message) {
    showErrorNotification(e.response.data.message);
  }
  return Promise.reject(e.response);
});

export { HTTP };
