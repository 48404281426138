import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { ContractsService } from '@/services';
import { FormControlClass } from '@/core';
import { FindAllOptions } from '@/services/contracts/interface';

@Component
export default class ContractSelect extends FormControlClass<string> {
  @Prop({ required: true })
  private readonly partnerId: string | undefined;

  @Prop({ default: false })
  private readonly isPartnerRequired!: boolean;

  @Prop()
  private readonly isDisabled: boolean | undefined;

  @Prop()
  private readonly isNotExpired: boolean | undefined;

  @Watch('partnerId')
  private async partnerIdChanged(): Promise<void> {
    this.setData();
  }

  protected async setData(): Promise<void> {
    if (this.isPartnerRequired && !this.partnerId) return;

    try {
      this.isLoading = true;

      const options: FindAllOptions = { page: 1, limit: 9999, isConfirmed: true, isNotExpired: true };

      if (this.partnerId) {
        options.partnerId = this.partnerId;
      }

      if (this.isDisabled !== undefined) {
        options.isDisabled = this.isDisabled;
      }

      if (this.isNotExpired) {
        options.isNotExpired = true;
      }

      const { data } = await ContractsService.findAll(options);

      this.items = data.map(ot => ({ value: ot.id, text: ot.name }));

      if (this.items.length === 1 && !this.value) {
        this.valueChanged(this.items[0].value);
      }
    } finally {
      this.isLoading = false;
    }
  }

  @Emit('isValidated')
  public isValidated() {
    return this.value == null;
  }
}
