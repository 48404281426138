import { VuexModule, Module, Mutation } from 'vuex-module-decorators';
import { OrganizationItem, OrganizationPartnerItem } from '@/services';

@Module({ namespaced: true })
class Organization extends VuexModule {
  public organizations: OrganizationItem[] = []
  public partners: OrganizationPartnerItem[] = []

  @Mutation
  public setOrganizations(organizations: OrganizationItem[]): void {
    this.organizations = organizations;
  }

  @Mutation
  public setPartners(partners: OrganizationPartnerItem[]): void {
    this.partners = partners;
  }

  get getOrganizationById() {
    return (id: string): OrganizationItem | undefined => this.organizations.find(o => o.id === id);
  }

  get getPartnerById() {
    return (id: string): OrganizationPartnerItem | undefined => this.partners.find(o => o.id === id);
  }
}

export default Organization;
