import { StoreVehicle, UpdateVehicle, VehicleItem } from '@/services';
import { HTTP, Pagination, PaginationOptions } from '@/core';
import { VehicleScheduleItem } from '@/services/vehicle/interface';
import { DetailedTrackItem } from './interface/detailedTrackItem.interface';

export class VehicleService {
  static async store(formData: StoreVehicle): Promise<VehicleItem> {
    const { data } = await HTTP.post('vehicles', formData);

    return data;
  }

  static async storeDraft(formData: StoreVehicle): Promise<VehicleItem> {
    const { data } = await HTTP.post('vehicles/draft', formData);

    return data;
  }

  static async DraftTransfer(vehicleId: string): Promise<VehicleItem> {
    const { data } = await HTTP.post(`vehicles/draft/${vehicleId}/transfer`);
    return data;
  }

  static async findAll(params: PaginationOptions): Promise<Pagination<VehicleItem>> {
    const { data } = await HTTP.get('vehicles', { params });

    return data;
  }

  static async getVehicleStage(vehicleId: string) {
    const { data } = await HTTP.get(`vehicles/${vehicleId}/stage`);

    return data;
  }
  static async findAllDraft(params: PaginationOptions): Promise<Pagination<VehicleItem>> {
    const { data } = await HTTP.get('vehicles/draft', { params });

    return data;
  }
  static async findOne(id: string): Promise<VehicleItem> {
    const { data } = await HTTP.get(`vehicles/${id}`);

    return data;
  }
  static async findDraftedOne(id: string): Promise<VehicleItem> {
    const { data } = await HTTP.get(`vehicles/draft/${id}`);

    return data;
  }
  // TODO change any on type
  static async getTrack(id: string, from: number, to: number): Promise<any> {
    const { data } = await HTTP.get(`vehicles/${id}/track`, { params: { from, to } });

    return data;
  }

  static async getDetailedTrack(id: string, from: number, to: number): Promise<DetailedTrackItem[]> {
    const { data } = await HTTP.get(`vehicles/${id}/detailed-track`, { params: { from, to } });

    return data;
  }

  static async update(vehicleId: string, vehicle: UpdateVehicle): Promise<VehicleItem> {
    const { data } = await HTTP.patch(`vehicles/${vehicleId}`, vehicle);

    return data;
  }
  static async updateDraft(vehicleId: string, vehicle: UpdateVehicle): Promise<VehicleItem> {
    const { data } = await HTTP.patch(`vehicles/draft/${vehicleId}`, vehicle);

    return data;
  }

  static async delete(vehicleId: string): Promise<void> {
    await HTTP.delete(`vehicles/${vehicleId}`);
  }

  static async deleteDraft(vehicleId: string): Promise<void> {
    await HTTP.delete(`vehicles/draft/${vehicleId}`);
  }

  static async getSchedule(): Promise<VehicleScheduleItem[]> {
    const { data } = await HTTP.get('vehicles/schedule');

    return data;
  }

  static async isOnline(vehicleId: string): Promise<boolean> {
    const { data } = await HTTP.get<{ isOnline: boolean }>(`vehicles/${vehicleId}/is-online`);
    return data.isOnline;
  }
}
