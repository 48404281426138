import { VuexModule, Module, Mutation } from 'vuex-module-decorators';
import { OrganizationItem } from '@/services';

@Module({ namespaced: true })
class Distributor extends VuexModule {
  public organizations: OrganizationItem[] | null = null
  @Mutation
  public setOrganizations(organizations: OrganizationItem[]): void {
    this.organizations = organizations;
  }

  get getOrganization() {
    return this.organizations;
  }
}

export default Distributor;
