import { baseHTTP, Pagination, PaginationOptions } from '@/core';
import { TimezoneItem } from './interface';

export class TimezoneService {
  static async findAll(options: PaginationOptions): Promise<Pagination<TimezoneItem>> {
    const { data } = await baseHTTP.get('shared/timezones', { params: options });

    return data;
  }
}
