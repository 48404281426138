import { Component, Prop, Watch } from 'vue-property-decorator';
import { FormControlClass } from '@/core';
import FullImageView from '../FullImageView/FullImageView.vue';

@Component({ components: { FullImageView } })
export default class ImageSlider extends FormControlClass<string> {
  @Prop({ required: true })
  private readonly images: string[] | undefined;

  public selectedIndex = 0;
  private interval: number | null = null;
  public isAutoPlay = false;

  public nextImage(): void {
    if (!this.images) return;

    if (this.images[this.selectedIndex + 1]) {
      this.selectedIndex += 1;
    } else {
      this.selectedIndex = 0;
    }
  }

  public prevImage(): void {
    if (!this.images) return;

    if (this.selectedIndex - 1 < 0) {
      this.selectedIndex = this.images.length - 1;
    } else {
      this.selectedIndex -= 1;
    }
  }

  public changeAutoPlay(): void {
    if (!this.images) return;

    this.isAutoPlay = !this.isAutoPlay;

    if (this.isAutoPlay) {
      if (this.images.length > 1) {
        this.interval = setInterval(() => this.nextImage(), 5000);
      }
    } else if (this.interval) {
      clearInterval(this.interval);
    }
  }

  @Watch('images', { immediate: true })
  private imagesChanged(val: string[]): void {
    if (val.length > 1) {
      this.isAutoPlay = true;
      this.interval = setInterval(() => this.nextImage(), 5000);
    } else {
      this.isAutoPlay = false;
      if (this.interval) {
        clearInterval(this.interval);
      }
    }
  }
}
