import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ValidationResult } from '@/core';
/**
 * Wrapper for vuetify elements.
 */
@Component
export default class FormControlV2Class<T, K> extends Vue {
  @Prop({ default: '' })
  public readonly value!: T;

  @Prop({ default: '' })
  public readonly label!: string;

  @Prop({ default: () => [] })
  public readonly rules!: ValidationResult[];

  @Prop({ default: false })
  public readonly hideDetails!: boolean;

  @Prop()
  public readonly color: string | undefined;

  @Prop({ default: true })
  public readonly outlined!: boolean;

  @Prop()
  public readonly selectedItem: K | undefined;

  public isLoading = false;
  public items: K[] = [];
  public search = '';
  protected paginationOptions: { page: number; limit: number, search: string } = {
    page: 1,
    limit: 10,
    search: '',
  }
  protected debounce: number | null = null;

  public mounted(): void {
    this.fetchMoreData();
  }
  /**
   * Call when element reach end of item lists or component was mounted, for example autocomplete
   */
  protected fetchMoreData(): void {
    // Call when element reach end of item lists
  }

  public endIntersect(entries: unknown, observer: unknown, isIntersecting: boolean): void {
    if (this.isLoading || !isIntersecting) return;

    this.paginationOptions.page += 1;

    this.fetchMoreData();
  }

  public searchChanged(): void {
    this.paginationOptions.search = this.search;

    if (this.debounce) {
      clearTimeout(this.debounce);
    }

    this.debounce = setTimeout(
      () => {
        this.paginationOptions.page = 1;

        this.fetchMoreData();
      },
      500,
    );
  }

  @Emit('input')
  public valueChanged(value: T | T[]): T | T[] {
    return value;
  }
}
