import { render, staticRenderFns } from "./ApprovalTag.vue?vue&type=template&id=6d17e360&scoped=true"
import script from "./ApprovalTag.ts?vue&type=script&lang=ts&external"
export * from "./ApprovalTag.ts?vue&type=script&lang=ts&external"
import style0 from "./ApprovalTag.scss?vue&type=style&index=0&id=6d17e360&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d17e360",
  null
  
)

export default component.exports