import dayjs from 'dayjs';
import i18n from '@/plugins/i18n';
/**
 * Return current date in unix
 */
export function currentDate(): number {
  return +(Date.now() / 1000).toFixed(0);
}
/**
 * Return start of current day unix
 */
export function startOfDay(date: number = Date.now()): number {
  return dayjs(date).startOf('day').unix();
}
/**
 * Return end of current day unix
 */
export function endOfDay(date: number = Date.now()): number {
  return dayjs(date).endOf('day').unix();
}

export function toUnix(date: string): number {
  return dayjs(date).unix();
}

export function transformDate(date: number | undefined, format = 'DD.MM.YYYY') {
  if (date) {
    if (date.toString().length < 10) {
      return i18n.tc('general.filters.invalidDateText');
    }

    // Hack for transform unix time in unix timestamp (server can send both the unix and the unix timestamp)
    if (date.toString().length === 10) {
      return dayjs(date * 1000).format(format);
    }
  }

  return dayjs(date).format(format);
}

export function formattedDateTime(date: number) {
  return dayjs.unix(date).format('YYYY-MM-DD');
}
export function formattedTime(Time: number) {
  return dayjs.unix(Time).format('HH:mm');
}

export function dataTimeParser(dataTime: string, isFinished = false): string {
  const timeArr = dataTime.substring(0, 5).split(':');

  let time = dataTime;

  if (timeArr[0] && +timeArr[0] > 23) {
    time = `23:${timeArr[1]}`;
    timeArr[0] = '23';
  }

  if (timeArr[1] && +timeArr[1] > 59) {
    time = `${timeArr[0]}:59`;
  }

  if (isFinished && !timeArr[1]) {
    time = `${timeArr[0]}:00`;
  }

  if (isFinished && timeArr[0].length === 1) {
    time = `0${time}`;
  }

  return time;
}
