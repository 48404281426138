import { getPreciseDistance } from 'geolib';

export function calculateRouteDistance(route: [number, number][]): number {
  let total = 0;
  route.forEach((r, idx) => {
    if (route[idx + 1]) {
      total += getPreciseDistance(
        { latitude: r[0], longitude: r[1] },
        { latitude: route[idx + 1][0], longitude: route[idx + 1][1] },
        0.5,
      );
    }
  });

  return total;
}
