import { HTTP } from '@/core';
import { VehicleTypeItem } from './interface';

export class VehicleTypeService {
  static async store(name: string, averageSpace: number, icon?: string): Promise<VehicleTypeItem> {
    const payload: { name: string; averageSpace: number, icon?: string } = { name, averageSpace };

    if (icon) payload.icon = icon;

    const { data } = await HTTP.post('vehicles/types', payload);

    return data;
  }

  static async findAll(): Promise<VehicleTypeItem[]> {
    const { data } = await HTTP.get('vehicles/types');

    return data;
  }

  static async update(id: string, name: string, averageSpace: number, icon?: string): Promise<VehicleTypeItem> {
    const payload: { name: string; averageSpace: number, icon?: string } = { name, averageSpace };

    if (icon) payload.icon = icon;

    const { data } = await HTTP.patch(`vehicles/types/${id}`, payload);

    return data;
  }

  static async delete(id: string): Promise<void> {
    await HTTP.delete(`vehicles/types/${id}`);
  }

  static async suggest(space: number): Promise<string[]> {
    const { data } = await HTTP.get('vehicles/types/suggest', { params: { space } });

    return data.suggestedTypes;
  }
}
