import { Component, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Notification } from '@/store';
import { Validator } from '../utils/validator';

const sharedStore = namespace('Shared');
/**
 * Contain all necessary methods and events for work with forms.
 */
@Component
export default class FormClass<T> extends Vue {
  @Ref() public readonly formEl!: { resetValidation: () => void };

  @sharedStore.Mutation
  protected readonly setNotification!: (notification: Notification) => void;

  public validator = Validator;
  public isLoading = false;
  public isFormLoading = false;
  public isFormValid = false;
  /**
   * Using for edit action
   */
  public id: string | number | null = null;
  public formData: T | undefined;
  /**
   * Using for reset form values for default
   */
  public originalFormData: T | undefined;

  public mounted(): void {
    if (this.formData) {
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
    }
  }

  /**
   * Use for submit form triggered next methods store or update depends on id property of class
   */
  public submit(): void {
    if (this.id) {
      this.update();
    } else {
      this.store();
    }
  }
  /**
   * Fired for store action if class property id not exist
   */
  public async store(): Promise<void> {
    // Fired for store action
  }
  /**
   * Fired for store action if class property id exist
   */
  public async update(): Promise<void> {
    // Fired for store action
  }
  /**
   * Check is disable btn depends on class properties isFormValid and isLoading
   */
  get isSubmitDisable(): boolean {
    return !this.isFormValid || this.isLoading;
  }
}
