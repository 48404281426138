import { Component } from 'vue-property-decorator';
import { FormControlV2, PaginationOptions, VuetifySelectItem } from '@/core';
import { TimezoneService } from '@/services';

@Component
export default class TimezoneSelect extends FormControlV2<string, VuetifySelectItem<string>> {
  public search = '';
  public debounce: number | null = null;

  protected async fetchMoreData(): Promise<void> {
    try {
      this.isLoading = true;

      const options: PaginationOptions = {
        page: this.paginationOptions.page,
        limit: this.paginationOptions.limit,
      };

      if (this.paginationOptions.search) {
        options.search = this.paginationOptions.search;
      }

      const { data } = await TimezoneService.findAll(options);

      if (this.paginationOptions.page === 1) {
        this.items = data.map(d => ({ value: d.id, text: d.name }));
      } else {
        this.items = [...this.items as VuetifySelectItem<string>[], ...data.map(d => ({ value: d.id, text: d.name }))];
      }

      if (this.selectedItem) {
        const idx = this.items.findIndex(i => i.value === this.selectedItem?.value);

        if (idx === -1) {
          this.items.unshift(this.selectedItem);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }
}
