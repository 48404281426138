import jwtDecode from 'jwt-decode';
import store from '@/store';
import { AccessTokenSignature } from '@/services';

export function setUserInfoFromJWT(token: string): void {
  const user = jwtDecode<AccessTokenSignature>(token);

  try {
    store.commit('Shared/setUserInfo', {
      id: user.id,
      login: user.login,
      role: user.role,
      email: user.email,
      organizationId: user.organizationId,
      organizationName: user.organizationName,
      organizationType: user.organizationType,
      organizationCode: user.organizationCode,
      organizationLogo: user.organizationLogo,
      timezone: {
        id: user.timezone.id,
        name: user.timezone.name,
      },
      rules: user.rules,
    });
  } catch (e) {
    localStorage.removeItem('accessToken');
  }
}
