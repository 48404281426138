import { Component } from 'vue-property-decorator';
import { DialogFormClass } from '@/core';
import { DialogWindow, DriverSelect } from '@/components';
import { VehicleService } from '@/services';
import router from '@/router';

@Component({
  components: { DialogWindow, DriverSelect },
})
export default class AssignDriverDialog extends DialogFormClass {
  public driverId: string | undefined = '';
  private vehicleId: string | undefined = '';

  protected dialogOpened(id?:string, driverId?:string): void {
    if (!this.$route.params.id) {
      this.vehicleId = id;
      this.driverId = driverId;
    } else {
      this.vehicleId = this.$route.params.id;
      this.driverId = driverId;
    }
  }

  public async assign(): Promise<void> {
    if (!this.vehicleId) return;
    try {
      this.isLoading = true;

      await VehicleService.update(this.vehicleId, { driverId: this.driverId });

      this.modelChanged();

      if (this.driverId) {
        this.setNotification({ text: this.$t('pages.vehicles.assignDriverDialog.driverAssignedMessage').toString() });
        await router.push('/vehicles');
      } else {
        this.setNotification({
          text: this.$t('pages.vehicles.assignDriverDialog.driverUnassignedMessage').toString(),
          type: 'warning',
        });
        await router.push('/vehicles');
      }

      this.close();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }
}
