import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { UserModel, UserRule } from '@/core/enums';
import { canEnter, isAlreadyAuth, isAuth } from './guards';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    component: () => import('@/components/Layouts/AuthLayout/AuthLayout.vue'),
    beforeEnter: isAlreadyAuth,
    children: [
      {
        path: '/',
        component: () => import('@/pages/LoginUserPage/LoginUserPage.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/components/Layouts/HomeLayout/HomeLayout.vue'),
    beforeEnter: isAuth,
    children: [
      {
        path: '/',
        component: () => import('@/pages/HomePage/HomePage.vue'),
        name: 'Home',
      },
      {
        path: '/dashboards',
        component: () => import('@/pages/DashboardPage/DashboardPage.vue'),
      },
      {
        path: '/organizations',
        component: () => import('@/pages/OrganizationsPage/OrganizationsPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.ORGANIZATIONS, rule: UserRule.READ },
      },
      {
        path: '/partners',
        component: () => import('@/pages/OrganizationPartnersPage/OrganizationPartnersPage.vue'),
        meta: { model: UserModel.PARTNER, rule: UserRule.READ },
      },
      {
        path: '/users',
        component: () => import('@/pages/UsersPage/UsersPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.USER, rule: UserRule.READ },
      },
      {
        path: '/vehicles-dictionaries',
        component: () => import('@/pages/VehiclesDictionariesPage/VehiclesDictionariesPage.vue'),
        beforeEnter: canEnter,
      },
      {
        path: '/vehicles',
        component: () => import('@/pages/VehiclesPage/VehiclesPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.VEHICLE, rule: UserRule.READ },
      },
      {
        path: '/vehicle/:id',
        component: () => import('@/pages/VehiclePage/VehiclePage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.VEHICLE, rule: UserRule.READ },
      },
      {
        path: '/vehicles/:id/edit',
        component: () => import('@/pages/VehicleStepperForm/VehicleStepperForm.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.VEHICLE, rule: UserRule.READ },
      },
      {
        path: '/vehicles/drafted/:id/edit',
        component: () => import('@/pages/VehicleStepperForm/VehicleStepperForm.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.VEHICLE, rule: UserRule.READ },
      },
      {
        path: '/vehicles/new',
        component: () => import('@/pages/VehicleStepperForm/VehicleStepperForm.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.VEHICLE, rule: UserRule.READ },

      },
      {
        path: '/vehicles-schedule',
        component: () => import('@/pages/VehicleSchedulePage/VehicleSchedulePage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.VEHICLE, rule: UserRule.READ },
      },
      {
        path: '/vehicles-monitoring',
        component: () => import('@/pages/VehiclesMonitoringPage/VehiclesMonitoringPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.VEHICLE, rule: UserRule.READ },
      },
      {
        path: '/drivers',
        component: () => import('@/pages/DriverPage/DriverPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.USER, rule: UserRule.READ },
      },
      {
        path: '/drivers/new',
        component: () => import('@/pages/DriverCreatePage/DriverCreatePage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.USER, rule: UserRule.READ },
      },
      {
        path: '/drivers/:id/edit',
        component: () => import('@/pages/DriverCreatePage/DriverCreatePage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.USER, rule: UserRule.READ },
      },
      {
        path: '/contacts',
        name: 'contacts',
        component: () => import('@/pages/ContactsPage/ContactsPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.CONTACT, rule: UserRule.READ },
      },
      {
        path: '/locations',
        component: () => import('@/pages/LocationsPage/LocationsPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.LOCATION, rule: UserRule.READ },
      },
      {
        path: '/operations',
        component: () => import('@/pages/OperationsPage/OperationsPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.OPERATION, rule: UserRule.READ },
      },
      {
        path: '/contracts',
        component: () => import('@/pages/ContractsPage/ContractsPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.CONTRACT, rule: UserRule.READ },
      },
      {
        path: '/order-types',
        component: () => import('@/pages/OrderTypesPage/OrderTypesPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.ORDER_TYPE, rule: UserRule.READ },
      },
      {
        path: '/orders',
        name: 'orders',
        beforeEnter: canEnter,
        component: () => import('@/pages/OrdersPage/OrdersPage.vue'),
        meta: { model: UserModel.ORDER, rule: UserRule.READ },
      },
      {
        path: '/orders/:id',
        component: () => import('@/pages/OrderPage/OrderPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.ORDER, rule: UserRule.READ },

      },
      {
        path: '/orders/:id/edit',
        component: () => import('@/pages/OrderStepperForm/OrderStepperForm.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.ORDER, rule: UserRule.READ },
      },
      {
        path: '/order/new',
        component: () => import('@/pages/OrderStepperForm/OrderStepperForm.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.ORDER, rule: UserRule.READ },
      },
      {
        path: '/cargos',
        component: () => import('@/pages/CargosPage/CargosPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.CARGO, rule: UserRule.READ },
      },
      {
        path: '/trips',
        name: 'trips',
        component: () => import('@/pages/TripsPage/TripsPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.TRIP, rule: UserRule.READ },
      },
      {
        path: '/trips/new',
        component: () => import('@/pages/TripsPage/components/TripDialog/TripDialog.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.TRIP, rule: UserRule.READ },
      },
      {
        path: '/trips/:id/edit',
        component: () => import('@/pages/TripsPage/components/TripDialog/TripDialog.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.TRIP, rule: UserRule.READ },
      },
      {
        path: '/reports',
        component: () => import('@/pages/ReportsPage/ReportsPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.REPORT, rule: UserRule.READ },
      },
      {
        path: '/routes-zones',
        component: () => import('@/pages/RouteZonePage/RouteZonePage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.ROUTE_ZONE, rule: UserRule.READ },
      },
      {
        path: '/routes',
        component: () => import('@/pages/RoutesPage/RoutesPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.ROUTE, rule: UserRule.READ },
      },
      {
        path: '/epc',
        component: () => import('@/pages/EPCPage/EPCPage.vue'),
        beforeEnter: canEnter,
        meta: { model: UserModel.TRIP, rule: UserRule.READ },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
