import { Component, Prop, Watch } from 'vue-property-decorator';
import { FormControlClass } from '@/core';

@Component
export default class DocumentSlider extends FormControlClass<string> {
  @Prop({ required: true })
  private readonly documents: { url: string; type: string }[] | undefined;

  public selectedIndex = 0;
  private interval: number | null = null;
  public isAutoPlay = false;

  public nextDocument(): void {
    if (!this.documents) return;

    if (this.documents[this.selectedIndex + 1]) {
      this.selectedIndex += 1;
    } else {
      this.selectedIndex = 0;
    }
  }

  public open(url: string): void {
    window.open(url, '_blank');
  }

  public prevDocument(): void {
    if (!this.documents) return;

    if (this.selectedIndex - 1 < 0) {
      this.selectedIndex = this.documents.length - 1;
    } else {
      this.selectedIndex -= 1;
    }
  }

  public changeAutoPlay(): void {
    if (!this.documents) return;

    this.isAutoPlay = !this.isAutoPlay;

    if (this.isAutoPlay) {
      if (this.documents.length > 1) {
        this.interval = setInterval(() => this.nextDocument(), 5000);
      }
    } else if (this.interval) {
      clearInterval(this.interval);
    }
  }

  @Watch('documents', { immediate: true })
  private imagesChanged(val: string[]): void {
    if (val.length > 1) {
      this.isAutoPlay = true;
      this.interval = setInterval(() => this.nextDocument(), 5000);
    } else {
      this.isAutoPlay = false;
      if (this.interval) {
        clearInterval(this.interval);
      }
    }
  }
}
