import { VuexModule, Module, Mutation } from 'vuex-module-decorators';
import { VehicleItem } from '@/services';

@Module({ namespaced: true })
export class Vehicle extends VuexModule {
  public vehicles: VehicleItem[] = [];

  @Mutation
  public setVehicles(vehicles: VehicleItem[]): void {
    this.vehicles = vehicles;
  }

  get findVehicleById() {
    return (id: string): VehicleItem | undefined => this.vehicles.find(v => v.id === id);
  }
}
