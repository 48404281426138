import { VuexModule, Module, Mutation } from 'vuex-module-decorators';
import { CargoItem } from '@/services/cargos/interface';

@Module({ namespaced: true })
export class Cargo extends VuexModule {
  public cargos: CargoItem[] = []

  @Mutation
  public setCargos(cargos: CargoItem[]): void {
    this.cargos = cargos;
  }

  get getCargoById() {
    return (id: string): CargoItem | undefined => this.cargos.find(c => c.id === id);
  }
}
