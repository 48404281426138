import { render, staticRenderFns } from "./MapWrapper.vue?vue&type=template&id=0b98d3b8&scoped=true"
import script from "./MapWrapper.ts?vue&type=script&lang=ts&external"
export * from "./MapWrapper.ts?vue&type=script&lang=ts&external"
import style0 from "./MapWrapper.scss?vue&type=style&index=0&id=0b98d3b8&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b98d3b8",
  null
  
)

export default component.exports