import { baseHTTP } from '@/core';
import { CountryItem } from './interface';

export default class CountryService {
  static async findAll(): Promise<CountryItem[]> {
    const { data } = await baseHTTP.get('shared/countries');

    return data;
  }
}
