import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { AuthService, OrganizationService } from '@/services';
import { UserInfo } from '@/store';
import { namespace } from 'vuex-class';
import { OrganizationType } from '@/core/enums';

const sharedStore = namespace('Shared');

@Component
export default class AppMenu extends Vue {
  @sharedStore.State
  private readonly userInfo!: UserInfo;

  @Prop({ required: true })
  public readonly login!: string;

  @Prop({ required: true })
  public readonly organizationName!: string;

  @Prop({ required: true })
  public readonly organizationLogo!: string | null;

  @Prop({ required: true })
  public showOrganizationSettingsIcon!: boolean;

  @Prop({ default: true })
  public showDrawerIcon!: boolean;

  public EPCCount = 0;
  public isClicked = true;

  public mounted() {
    AuthService.isBlocked()
      .then(isBlocked => {
        if (isBlocked) {
          this.$emit('logout');
        }
      })
      .catch(e => console.error(e));

    this.getEPCCount();
    setInterval(() => this.getEPCCount(), 6e4);
  }
  @Emit('toggleDrawer')
  public toggleDrawer() {
    this.isClicked = !this.isClicked;
    return this.isClicked;
  }

  @Watch('userInfo')
  private userChanged(): void {
    this.getEPCCount();
  }

  public async getEPCCount(): Promise<void> {
    try {
      this.EPCCount = await OrganizationService.getEPCCount();
    } catch (e) {
      console.error(e);
    }
  }

  get showEPCCount(): boolean {
    return [OrganizationType.pl4, OrganizationType.pl3].includes(this.userInfo.organizationType);
  }
}
