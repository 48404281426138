import Vue from 'vue';
import Vuex from 'vuex';
import Shared from './modules/shared/shared.store';
import Organization from './modules/organization/organization.store';
import { VehicleMonitoring } from './modules/vehicleMonitoring/vehicleMonitoring.store';
import { Cargo } from './modules/cargo/cargo.store';
import { Vehicle } from './modules/vehicle/vehicle.store';
import Distributor from './modules/distributor/distributor.store';

Vue.use(Vuex);

export type { Notification, UserInfo, ConfirmDialog, ConfirmDialogOptions } from './modules/shared/interfaces';

export default new Vuex.Store({
  modules: {
    Shared,
    Organization,
    Vehicle,
    VehicleMonitoring,
    Cargo,
    Distributor,
  },
});
