export function isArraysSame(arr1: [number, number][], arr2: [number, number][]) {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  for (let i = 0; i < sortedArr1.length; i += 1) {
    const [x1, y1] = sortedArr1[i];
    const [x2, y2] = sortedArr2[i];

    if (x1 !== x2 || y1 !== y2) return false;
  }

  return true;
}
