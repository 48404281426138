import { Component, Emit } from 'vue-property-decorator';
import { FormControlClass } from '@/core';

@Component
export default class CurrencyInput extends FormControlClass<string> {
  public currency = '0.00';

  public mounted(): void {
    this.currencyChanged(this.value.toString(), true);
  }

  @Emit('input')
  public currencyChanged(val: string, isInputFinished = false): number {
    this.currency = val;

    if (!val) {
      this.currency = '0.00';
      return 0.00;
    }

    const arr = val.split('.');
    let value = val;

    if (!arr[1] && isInputFinished) {
      value = `${arr[0]}.00`;
    }

    if (arr[1] && arr[1].length > 2) {
      value = `${arr[0]}.${arr[1][0]}${arr[1][1]}`;
    }

    if (arr.length > 2) {
      value = `${arr[0]}.${arr[1]}`;
    }

    setTimeout(() => {
      this.currency = value;
    }, 50);

    return +value;
  }
}
