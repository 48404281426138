import { HTTP, Pagination, PaginationOptions } from '@/core';
import { SelfUpdateUserRequest, StoreUserRequest, UpdateUserRequest, UserItem, UserItemBase } from './interface';

export default class UserService {
  static async store(user: StoreUserRequest): Promise<UserItemBase> {
    const { data } = await HTTP.post('users', user);

    return data;
  }

  static async findAll(params?: PaginationOptions): Promise<Pagination<UserItemBase>> {
    const { data } = await HTTP.get('users', { params });

    return data;
  }

  static async findOne(userId: string): Promise<UserItem> {
    const { data } = await HTTP.get(`users/${userId}`);

    return data;
  }

  static async findAllDrivers(organizationId?: string): Promise<UserItem[]> {
    const { data } = await HTTP.get('users/drivers', { params: { organizationId } });

    return data;
  }

  static async update(userId: string, user: UpdateUserRequest): Promise<UserItemBase> {
    const { data } = await HTTP.patch(`users/${userId}`, user);

    return data;
  }

  static async selfUpdate(user: SelfUpdateUserRequest): Promise<void> {
    await HTTP.patch('users/self-update', user);
  }

  static async delete(userId: string): Promise<void> {
    await HTTP.delete(`users/${userId}`);
  }

  static async isExist(text: string): Promise<{ isExist: boolean }> {
    const { data } = await HTTP.post('users/is-exist', { text });

    return data;
  }
}
