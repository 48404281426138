import { HTTP } from '@/core';
import { DeviceItem, DeviceStopItem } from './interface';

export class TelemetryService {
  static async getDevices(): Promise<DeviceItem[]> {
    const { data } = await HTTP.get('telemetry/devices');

    return data;
  }

  static async getDeviceStops(deviceId: number, from: number, to: number): Promise<DeviceStopItem[]> {
    const { data } = await HTTP.get(`telemetry/device/${deviceId}/stops`, { params: { from, to } });

    return data;
  }
}
