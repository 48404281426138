import { Component, Prop } from 'vue-property-decorator';
import { DialogFormClass } from '@/core';
import { DialogWindow } from '@/components';

@Component({
  components: { DialogWindow },
})
export default class ImageViewDialog extends DialogFormClass {
  @Prop({ required: true })
  private readonly documentName: string | undefined;

  @Prop({ required: true })
  private readonly imageUrl: string | undefined;
}
