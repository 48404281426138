import { Component, Prop } from 'vue-property-decorator';
import { FormControlClass } from '@/core';
import { OrganizationType } from '@/core/enums';

@Component
export default class OrganizationTypeSelect extends FormControlClass<string> {
  @Prop()
  private readonly forType: OrganizationType | undefined;

  @Prop({ default: true })
  private readonly setDefaultValue!: boolean;

  public mounted(): void {
    if (this.forType === OrganizationType.pl5) {
      this.items = [
        OrganizationType.pl4,
        OrganizationType.pl3,
        OrganizationType.pl2,
        OrganizationType.pl1,
        OrganizationType.fleetOwner,
      ];
      if (this.setDefaultValue) this.valueChanged(OrganizationType.pl4);
    } else {
      this.items = [
        OrganizationType.pl5,
        OrganizationType.pl4,
        OrganizationType.pl3,
        OrganizationType.pl2,
        OrganizationType.pl1,
        OrganizationType.fleetOwner,
      ];
      if (this.setDefaultValue) this.valueChanged(OrganizationType.pl5);
    }
  }
}
