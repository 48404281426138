import { NavigationGuardNext, Route } from 'vue-router';
import { UserInfo } from '@/store/modules/shared/interfaces';
import { setUserInfoFromJWT } from '@/core';
import store from '@/store';

export async function isAuth(to: Route, from: Route, next: NavigationGuardNext) {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    next({ path: '/login' });
    return;
  }

  const stateUser: UserInfo = store.state.Shared.userInfo;
  if (!stateUser) {
    setUserInfoFromJWT(accessToken);
  }

  next();
}
