import { HTTP, Pagination } from '@/core';
import { CargoItem, StoreCargoRequest, FindAllOptions } from './interface';

export class CargosService {
  static async store(cargo: StoreCargoRequest): Promise<CargoItem> {
    const { data } = await HTTP.post('cargos', cargo);

    return data;
  }

  static async findAll(params: FindAllOptions): Promise<Pagination<CargoItem>> {
    const { data } = await HTTP.get('cargos', { params });

    return data;
  }

  static async findOneById(id: string): Promise<CargoItem> {
    const { data } = await HTTP.get(`cargos/${id}`);

    return data;
  }

  static async update(cargoId: string, cargos: Partial<StoreCargoRequest>): Promise<CargoItem> {
    const { data } = await HTTP.patch(`cargos/${cargoId}`, cargos);

    return data;
  }

  static async delete(cargoId: string): Promise<void> {
    await HTTP.delete(`cargos/${cargoId}`);
  }
}
