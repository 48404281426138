import { Component, Emit, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { OrganizationPartnerItem, OrganizationPartnersService, OrganizationSearchOptions } from '@/services';
import { FormControlClass } from '@/core';
import { OrganizationType } from '@/core/enums';

const organizationStore = namespace('Organization');

@Component
export default class PartnerSelect extends FormControlClass<string> {
  @organizationStore.Mutation
  private readonly setPartners!: (organizations: OrganizationPartnerItem[]) => void;

  @Prop()
  private readonly organizationType: OrganizationType[] | undefined;

  public async mounted(): Promise<void> {
    try {
      this.isLoading = true;

      const options: OrganizationSearchOptions = {
        page: 1,
        limit: 9999,
      };

      if (this.organizationType) {
        options.types = this.organizationType;
      }

      const { data } = await OrganizationPartnersService.findAll(options);

      this.setPartners(data);
      this.items = data.map(p => ({ value: p.id, text: p.name }));
    } finally {
      this.isLoading = false;
    }
  }
  @Emit('isValidated')
  public isValidated() {
    return this.value == null;
  }
}
