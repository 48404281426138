import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { FormControlClass } from '@/core';
import dayjs from 'dayjs';

@Component
export default class DateTimePicker extends FormControlClass<number> {
  @Prop({ default: false })
  protected readonly showError!: boolean

  public isDateSelectOpen = false;
  public isTimeSelectOpen = false;
  public date = dayjs().startOf('day').format('YYYY-MM-DD');
  public time = dayjs().startOf('day').format('HH:mm');

  public mounted(): void {
    this.incomeValueChanged();
  }

  @Watch('value', { immediate: true })
  private incomeValueChanged(val?: number): void {
    if (val) {
      this.date = dayjs(val * 1000).format('YYYY-MM-DD');
      this.time = dayjs(val * 1000).format('HH:mm');
    }
  }

  @Watch('time')
  private timeChanged(): void {
    const timeArr = this.time.split(':');

    if (timeArr[0] && +timeArr[0] > 23) {
      this.time = `23:${timeArr[1]}`;
      timeArr[0] = '23';
    }

    if (timeArr[1] && +timeArr[1] > 59) {
      this.time = `${timeArr[0]}:59`;
    }
  }

  @Emit('input')
  public dataChanged(): number {
    return dayjs(`${this.date} ${this.time}`).unix();
  }
}
