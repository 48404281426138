import { Component, Prop, Ref } from 'vue-property-decorator';
import { DialogClass } from '@/core';
import DialogWindow from '@/components/DialogWindow/DialogWindow.vue';
import { VehicleMarkerItem } from '@/store/modules/vehicleMonitoring/interfaces';
import MapWrapper from './MapWrapper/MapWrapper.vue';
import { MarkerItem, PolylineItem, GeofenceItem } from './interfaces';

@Component({
  components: { DialogWindow, MapWrapper },
})
export default class RouteMap extends DialogClass {
  @Ref() private readonly map!: { invalidateSize: () => void, fitBounds: () => void };

  @Prop({ default: 'dialog' })
  private readonly mode!: 'dialog' | 'flat';

  @Prop({ default: false })
  private readonly showDialogFullScreen!: boolean;

  @Prop()
  private readonly points: [number, number][] | undefined;

  @Prop({ default: () => [] })
  private readonly polyline!: PolylineItem[];

  @Prop()
  private readonly customMarker!: VehicleMarkerItem;

  @Prop()
  private readonly customMarkers!: MarkerItem[];

  @Prop({ default: () => [] })
  private readonly geofences!: GeofenceItem[];

  public fullscreen = false;

  protected async dialogOpened(): Promise<void> {
    this.fullscreen = false;
    setTimeout(() => {
      this.map.invalidateSize();
      this.map.fitBounds();
    }, 500);
  }

  public toggleFullscreen(): void {
    this.fullscreen = !this.fullscreen;
    setTimeout(() => this.map.invalidateSize(), 500);
  }
}
