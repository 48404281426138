import { Component, Prop } from 'vue-property-decorator';
import { OrderTypeService, OrganizationPartnersService } from '@/services';
import { FormControlClass } from '@/core';
import { UserInfo } from '@/store';
import { FindAllOptions } from '@/services/orderTypes/interface';
import { namespace } from 'vuex-class';

const sharedStore = namespace('Shared');

@Component
export default class OrderTypeSelect extends FormControlClass<string> {
  @Prop({ default: false, required: false })
  private readonly isPartnerRequired!: boolean;

  @sharedStore.State
  private readonly userInfo!: UserInfo;

  public partnersIds: string[] = [];

  public async mounted(): Promise<void> {
    if (this.isPartnerRequired && !this.partnersIds?.length) return;

    try {
      this.isLoading = true;
      const orgData = await OrganizationPartnersService.findAll({ page: 1, limit: 9999 });
      this.partnersIds = orgData.data.map(d => d.id);

      const options: FindAllOptions = { page: 1, limit: 9999 };

      if (this.partnersIds?.length) {
        options.partnersIds = this.partnersIds;
        options.partnersIds.push(
          this.userInfo.organizationId,
        );
      }

      const { data } = await OrderTypeService.findAll(options);

      this.items = [...new Set(data.map(ot => ({ value: ot.id, text: ot.name })))];
    } finally {
      this.isLoading = false;
    }
  }
}
