import isValEmpty from 'validator/es/lib/isEmpty';
import isValURL from 'validator/es/lib/isURL';
import isValEmail from 'validator/es/lib/isEmail';
import isValLength from 'validator/es/lib/isLength';
import isValUUID from 'validator/es/lib/isUUID';
import i18n from '@/plugins/i18n';

export class Validator {
  static isEmpty(val: CommonValue | CommonValue[], text?: string): ValidationResult {
    if (Array.isArray(val)) {
      return val.length > 0 || (text || i18n.tc('utils.validation.required'));
    }

    return !isValEmpty(Validator.safeString(val)) || (text || i18n.tc('utils.validation.required'));
  }

  static isURL(val: CommonValue, text?: string): ValidationResult {
    return isValURL(Validator.safeString(val)) || (text || i18n.tc('utils.validation.urlInvalid'));
  }

  static isEmail(val: CommonValue, text?: string): ValidationResult {
    return isValEmail(Validator.safeString(val)) || (text || i18n.tc('utils.validation.emailInvalid'));
  }

  static isLength(val: CommonValue, max: number, min: number, text?: string): ValidationResult {
    return isValLength(Validator.safeString(val), { min, max })
      || (text || `
      ${i18n.tc('validation.lengthMax')} ${max} ${i18n.tc('validation.lengthUnit')}
      ${i18n.tc('validation.lengthMin')} ${max} ${i18n.tc('validation.lengthUnit')}
    `);
  }

  static isLengthMax(val: CommonValue, max: number, text?: string): ValidationResult {
    return Validator.safeString(val).length <= max
      || (text || `${i18n.tc('utils.validation.isLengthMax1')} ${max} ${i18n.tc('utils.validation.isLengthMax2')}`);
  }

  static isLengthMin(val: CommonValue, min: number, text?: string): ValidationResult {
    return Validator.safeString(val).length >= min
      || (text || `${i18n.tc('utils.validation.isLengthMin')} ${min} ${i18n.tc('utils.validation.isLengthMax2')}`);
  }

  static isUUID(val: CommonValue, text?: string): ValidationResult {
    return isValUUID(Validator.safeString(val)) || (text || i18n.tc('utils.validation.UUIDInvalid'));
  }

  private static safeString(val: CommonValue): string {
    // @ts-ignore
    if (!val && !val === 0) return '';

    try {
      return val!.toString();
    } catch {
      return '';
    }
  }
}

type CommonValue = string | number | undefined | null;
export type ValidationResult = boolean | string;
