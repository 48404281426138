import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ToolBar extends Vue {
  @Prop({ default: '' })
  public readonly title!: string;

  // TODO Deprecated
  @Prop({ default: false })
  public readonly useSearch!: boolean;

  // TODO Deprecated
  @Prop()
  public readonly searchIconTooltip: string | undefined;

  // TODO Deprecated
  public isSearchActive = false;
  public search = '';

  @Prop()
  public readonly topButtons: { title: string, action: () => void }[] | undefined;

  @Prop({ default: '' })
  public readonly selectedTab!: string;
}
