import { HTTP } from '@/core';

export class TripBillDocumentService {
  static async store(tripId: string, file: string): Promise<{ id: string; url: string }> {
    const { data } = await HTTP.post(`trips/${tripId}/bill/documents`, { file });

    return data;
  }

  static async remove(tripId: string, documentId: string): Promise<void> {
    await HTTP.delete(`trips/${tripId}/bill/documents/${documentId}`);
  }
}
