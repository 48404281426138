import axios from 'axios';
import { showErrorNotification } from './errorHandler';

const baseHTTP = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL,
});

baseHTTP.interceptors.response.use(response => response, e => {
  if (e.response.data.message) {
    showErrorNotification(e.response.data.message);
  }
  return Promise.reject(e.response);
});

export { baseHTTP };
