import { HTTP, Pagination } from '@/core';
import { FindAllOptions, OrderTypeItem } from './interface';

export class OrderTypeService {
  static async store(orderType: StoreOrderType): Promise<OrderTypeItem> {
    const { data } = await HTTP.post('order-types', orderType);

    return data;
  }

  static async findAll(params: FindAllOptions): Promise<Pagination<OrderTypeItem>> {
    const { data } = await HTTP.get('order-types', { params });

    return data;
  }

  static async findOneById(id: string): Promise<OrderTypeItem> {
    const { data } = await HTTP.get(`order-types/${id}`);

    return data;
  }

  static async update(orderTypeId: string, orderType: StoreOrderType): Promise<OrderTypeItem> {
    const { data } = await HTTP.patch(`order-types/${orderTypeId}`, orderType);

    return data;
  }

  static async delete(orderTypeId: string): Promise<void> {
    await HTTP.delete(`order-types/${orderTypeId}`);
  }
}

interface StoreOrderType {
  name: string;
  code: string | null;
}
