import { HTTP, Pagination } from '@/core';
import { FindAllOptions, OperationItem, StoreOperationRequest } from './interface';

export class OperationService {
  static async store(operation: StoreOperationRequest): Promise<OperationItem> {
    const { data } = await HTTP.post('operations', operation);

    return data;
  }

  static async findAll(params: FindAllOptions): Promise<Pagination<OperationItem>> {
    const { data } = await HTTP.get('operations', { params });

    return data;
  }

  static async update(operationId: string, operation: Partial<StoreOperationRequest>): Promise<OperationItem> {
    const { data } = await HTTP.patch(`operations/${operationId}`, operation);

    return data;
  }

  static async delete(operationId: string): Promise<void> {
    await HTTP.delete(`operations/${operationId}`);
  }
}
