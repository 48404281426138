import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { ContactItem, ContactsService, PaginationOptions } from '@/services';
import { FormControlClass } from '@/core';

@Component
export default class ContactSelect extends FormControlClass<string, ContactItem> {
  @Prop()
  private readonly organizationId: string | undefined;

  @Prop()
  private readonly organizationType: string | undefined

  @Prop()
  private readonly excludeContactsWithUser: boolean | undefined;

  @Prop()
  private readonly isDelivery: boolean | undefined;

  @Prop()
  private readonly isBilling: boolean | undefined;

  @Prop()
  private readonly isNotification: boolean | undefined;

  @Prop()
  private readonly partnerId: string | undefined;

  @Prop()
  private readonly locationId: string | undefined;

  @Prop({ default: false })
  private readonly mergeContacts!: boolean;

  @Prop({ default: false })
  private readonly isPartnerRequired!: boolean;

  public searchInput = '';

  @Watch('searchInput')

  @Watch('partnerId')
  private async partnerIdChanged(): Promise<void> {
    await this.setData();
  }

  @Watch('locationId')
  private async locationIdIdChanged(): Promise<void> {
    await this.setData();
  }

  @Emit('navigate')
  public navigate() {
    return this.searchInput;
  }

  protected async setData(): Promise<void> {
    if (this.isPartnerRequired && !this.partnerId) return;

    try {
      this.isLoading = true;

      const options: PaginationOptions = {
        page: 1,
        limit: 9999,
      };

      if (this.organizationId) {
        options.organizationId = this.organizationId;
      }

      if (this.excludeContactsWithUser) {
        options.excludeContactsWithUser = true;
      }

      if (this.isDelivery) {
        options.isDelivery = true;
      }

      if (this.isBilling) {
        options.isBilling = true;
      }

      if (this.isNotification) {
        options.isNotification = true;
      }

      if (this.partnerId && !this.mergeContacts) {
        options.partnerId = this.partnerId;
      }

      if (this.locationId) {
        options.locationId = this.locationId;
      }

      const { data } = await ContactsService.findAll(options);
      this.originalItems = data;
      this.items = data.map(c => ({ value: c.id, text: `${c.firstName} ${c.middleName || ''} ${c.lastName}` }));

      if (this.mergeContacts && this.partnerId) {
        const partnerContacts = await ContactsService.findAll({ ...options, partnerId: this.partnerId });
        this.originalItems = [...data, ...partnerContacts.data];

        this.items = [
          ...this.items,
          ...partnerContacts.data
            .map(c => ({ value: c.id, text: `${c.firstName} ${c.middleName || ''} ${c.lastName}` })),
        ];
      }

      if (this.selectedItem) {
        // @ts-ignore
        this.items.push(this.selectedItem);
      }

      this.dataLoaded();
    } finally {
      this.isLoading = false;
    }
  }
}

// interface FormData extends Omit
//   <StoreOrderRequest,
//     'orderTypeId' | 'contractId' | 'operationId' | 'partnerId' | 'vehicleTypeId' | 'pointsContacts' | 'cargos'
//   > {
//   orderTypeId: string | null;
//   contractId: string | null;
//   operationId: string | null;
//   partnerId: string | null;
//   vehicleTypeId: string | null;
//   pointsContacts: {
//     id: string;
//     contactId: string | null;
//     locationId: string | null;
//     location: [number, number] | null,
//     index: number;
//   }[];
//   cargos: {
//     id: string;
//     cargoId: string | null;
//     packNumber: string;
//     quantity: number;
//     isDelivered: boolean;
//   }[]
// }
