import { currentDate, HTTP } from '@/core';
import { OrderBillItem, UpdateOrderBillRequest } from './interface';

export class OrderBillService {
  static async find(orderId: string): Promise<OrderBillItem> {
    const { data } = await HTTP.get(`orders/${orderId}/bill`);

    return data;
  }

  static async update(orderId: string, bill: UpdateOrderBillRequest): Promise<OrderBillItem> {
    const { data } = await HTTP.patch(`orders/${orderId}/bill`, bill);

    return data;
  }

  static async submit(orderId: string): Promise<void> {
    await HTTP.patch(`orders/${orderId}/bill/submit`);
  }

  static async approve(orderId: string): Promise<void> {
    await HTTP.patch(`orders/${orderId}/bill/approve`);
  }

  static async sendEmail(orderId: string): Promise<void> {
    await HTTP.post(`orders/${orderId}/bill/send-email`);
  }

  static async export(orderId: string): Promise<void> {
    const { data } = await HTTP.get(`orders/${orderId}/bill/export`, { responseType: 'blob' });

    const fileURL = window.URL.createObjectURL(new Blob([data]));
    const fileLink = document.createElement('a');

    const name = `${currentDate()}`;

    fileLink.href = fileURL;
    fileLink.setAttribute('download', `${name}.pdf`);
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  }
}
