import { Component } from 'vue-property-decorator';
import { CountryService } from '@/services';
import { FormControlClass } from '@/core';

@Component
export default class CountrySelect extends FormControlClass<string> {
  public async setData(): Promise<void> {
    try {
      this.isLoading = true;

      const countries = await CountryService.findAll();

      this.items = countries.map(c => ({ value: c.id, text: c.name }));
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }
}
