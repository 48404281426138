import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DataOptions } from 'vuetify';
import ToolBar from '@/components/ToolBar/ToolBar.vue';
import { ConfirmDialogOptions, Notification } from '@/store';
import { PaginationOptions } from '../interfaces/paginationOptions.interface';
import { VuetifyTableHeadedItem } from '../interfaces/vuetifyTableHeadedItem.interface';

const sharedStore = namespace('Shared');

@Component({
  components: { ToolBar },
})
/**
 * Contain all necessary methods and events for work with server side pagination.
 * Include ToolBar component
 */
export class DataTableClass<T> extends Vue {
  @sharedStore.Mutation
  protected readonly setNotification!: (notification: Notification) => void;

  @sharedStore.Action
  protected readonly confirm!: (options: ConfirmDialogOptions) => Promise<boolean>;

  /**
   * Turn on or off automatic search models on server side via pagination
   */
  protected useSearchPagination = true;
  public isLoading = false;
  public totalItems = 0;
  public tableOptions: Partial<DataOptions> = {
    page: 1,
    itemsPerPage: 20,
  };
  public footerProps = { 'items-per-page-options': [5, 10, 15, 20] }
  public headers: VuetifyTableHeadedItem[] = [];
  public items: T[] = [];
  public search = '';
  /**
   * Fired when any options of data table was changed.
   */
  protected setTableData(..._params: never): void {
    // Overwrite this method for your own data change implementation
  }

  get paginationOptions(): PaginationOptions {
    const pagination: PaginationOptions = {
      page: this.tableOptions.page,
      limit: this.tableOptions.itemsPerPage,
    };

    if (this.search.length >= 3) {
      pagination.search = this.search;
    } else {
      delete pagination.search;
    }

    return pagination;
  }

  @Watch('tableOptions')
  private tableOptionsChanged(): void {
    this.setTableData();
  }

  @Watch('search')
  private searchChanged(): void {
    if (!this.useSearchPagination) return;
    if (this.search.length >= 3 || !this.search.length) {
      this.setTableData();
    }
  }
}
