import { HTTP, Pagination } from '@/core';
import { CommonContractItem, FindAllOptions, StoreCommonContractRequest } from './interface';

export class CommonContractsService {
  static async store(contract: StoreCommonContractRequest): Promise<CommonContractItem> {
    const { data } = await HTTP.post('v1/contracts/common', contract);

    return data;
  }

  static async findAll(params: FindAllOptions): Promise<Pagination<CommonContractItem>> {
    const { data } = await HTTP.get('v1/contracts/common', { params });

    return data;
  }

  static async findOne(contractId: string): Promise<CommonContractItem> {
    const { data } = await HTTP.get(`v1/contracts/common/${contractId}`);

    return data;
  }

  static async update(contractId: string, contract: Partial<StoreCommonContractRequest>): Promise<CommonContractItem> {
    const { data } = await HTTP.patch(`v1/contracts/common/${contractId}`, contract);

    return data;
  }

  static async delete(contractId: string): Promise<void> {
    await HTTP.delete(`v1/contracts/common/${contractId}`);
  }
}
