import { Component, Prop, Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';

@Component
export default class ApprovalTag extends Vue {
  @Prop({ required: true })
  public text : string| undefined | VueI18n.LocaleMessages;

  @Prop({ required: true })
  public color : string| undefined;

  @Prop({ required: false, default: false })
  public changeImageUrl : boolean | undefined;

  @Prop({ required: true })
  public icon : string| undefined;

  @Prop({ required: false })
  public width : string| undefined;
}
