import { Component, Emit, Watch } from 'vue-property-decorator';
import { dataTimeParser, FormControlClass } from '@/core';

@Component
export default class TimeInput extends FormControlClass<string> {
  public time = ''

  public mounted(): void {
    this.time = this.value;
  }

  @Watch('value')
  private incomeValueChanged(): void {
    this.time = this.value;
  }

  @Emit('input')
  public timeChanged(isFinishInput = false): string {
    this.time = dataTimeParser(this.time, isFinishInput);

    return this.time;
  }
}
