import store, { UserInfo } from '@/store';
import { UserModel, UserRule } from '@/core/enums';
/**
 * Check is user has access to functionality by User model and rule
 */
export function hasAccess(model: UserModel, rule: UserRule): boolean {
  const user = store.state.Shared.userInfo as UserInfo | null;

  if (!user) return false;

  if (user.role === 'root') return true;

  return !!user.rules.find(u => u.model === model && u.rule === rule);
}
