import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { LMap, LTileLayer, LMarker, LPolyline, LTooltip, LPopup, LCircle, LPolygon } from 'vue2-leaflet';
import L, { DivIcon, divIcon, latLng, Map } from 'leaflet';
import MarkerCluster from 'vue2-leaflet-markercluster';
import LMovingRotatedMarker from 'vue2-leaflet-moving-rotated-marker';
import { randomString } from '@/core';
import { VehicleMarkerItem } from '@/store/modules/vehicleMonitoring/interfaces';
import { MarkerItem, PolylineItem, GeofenceItem } from '../interfaces';

@Component({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LMovingRotatedMarker,
    LTooltip,
    LPopup,
    LCircle,
    LPolygon,
    MarkerCluster,
  },
})
export default class MapWrapper extends Vue {
  @Ref() private readonly map!: { mapObject: Map };

  @Prop()
  private readonly points: [number, number][] | undefined;

  @Prop({ default: () => [] })
  private readonly polyline!: PolylineItem[];

  @Prop()
  private readonly customMarker!: VehicleMarkerItem;

  @Prop()
  private readonly customMarkers!: MarkerItem[];

  @Prop({ default: () => [] })
  private readonly geofences!: GeofenceItem[];

  @Prop({ default: '400px' })
  private readonly height!: string;

  public center = latLng(0, 0);
  public zoom = 13;

  public mapReady(): void {
    this.map.mapObject.invalidateSize();
  }

  public mounted(): void {
    this.invalidateSize();
    this.fitBounds();
  }

  public invalidateSize(): void {
    setTimeout(() => this.map.mapObject.invalidateSize(), 100);
  }

  public fitBounds(): void {
    setTimeout(() => {
      if (!this.points || !this.points.length) return;

      this.map.mapObject.fitBounds(this.points);
    }, 500);
  }

  public getPointMarker(index: number): DivIcon {
    return divIcon({
      html: `<div>${index}</div>`,
      className: 'point-marker',
    });
  }

  get polylineItems(): LocalPolylineItem[] {
    return this.polyline.map(p => ({ ...p, id: randomString() }));
  }

  public iconCreateFunction(cluster: any) {
    return L.divIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: 'cluster-marker',
      iconSize: L.point(40, 40),
      iconAnchor: L.point(20, 20),
    });
  }

  // @Watch('points')
  // private pointsChanged(): void {
  //   setTimeout(() => {
  //     if (!this.points || !this.points.length) return;
  //
  //     this.map.mapObject.fitBounds([this.points[this.points.length - 1]]);
  //   }, 500);
  // }

  @Watch('polyline', { immediate: true })
  private polylineChanged(): void {
    if (!this.map) {
      setTimeout(() => {
        this.polylineChanged();
      }, 500);
      return;
    }

    if (this.polyline.length && this.points) {
      this.map.mapObject.fitBounds(this.points, { padding: [0, 50] });
    }
  }
}

interface LocalPolylineItem extends PolylineItem {
  id: string;
}
