import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { FormControlClass } from '@/core';
import dayjs from 'dayjs';

@Component
export default class DatePicker extends FormControlClass<number> {
  @Prop({ default: false })
  private readonly defaultNull!: boolean

  public isOpen = false;
  public date: string | null = '';
  public daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  public getDay(date: number) {
    const i = new Date(date).getDay();
    return this.daysOfWeek[i];
  }
  public mounted(): void {
    this.incomeValueChanged();
  }

  @Watch('value')
  private incomeValueChanged(): void {
    if (!this.value) {
      this.date = null;
    } else {
      this.date = dayjs(this.value * 1000).format('YYYY-MM-DD');
    }
  }

  @Emit('input')
  public dataChanged(value: string | null): number | null {
    return value ? dayjs(value).unix() : null;
  }
}
